import { useEffect, useCallback } from 'react';
import { userRoles } from '../../app/data/default';
import { UIInfoNoAccess } from 'shared/ui/info';
import { usePageContext } from 'shared/context/page';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { ApiUserNew } from 'entities/users/api';
import { IUsersNew } from 'entities/users/models';
import { FormGenerate } from 'shared/ui/form';
import { devLog } from 'shared/lib/default';
import { useAuthContext } from 'shared/context/auth';

interface GetData {
  login: string;
  name?: string;
  email?: string;
  role?: string;
  password?: string;
  verify_pass: string;
}

export function UserNewPage() {
  //=============
  const pageCode: string = 'users.newadf';
  //=============
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const { updatePage, refreshPage, setErrorPage } = usePageContext();
  //----
  //const FormDefalt = { login: '', name: '', email: '', password: '', verifypass: 'Internal', role: 'user' };
  //const [form, setForm] = useState(FormDefalt);

  //----- Очистка формы
  const CleanForm = () => {
    //  setForm(FormDefalt);
  };
  //========
  const { loading, response: apiUsers, error, success } = ApiUserNew(); //  API
  //---
  const apiSave = useCallback(
    async (form: any) => {
      devLog('apiSave', 'ok');
      devLog('form', JSON.stringify(form));
      let required = true;
      let dataRes: IUsersNew = { login: '', verify_pass: form.verifypass };
      if (form.login == '') {
        required = false;
      } else {
        dataRes.login = form.login;
      }
      if (form.name == '') {
        required = false;
      } else {
        dataRes.name = form.name;
      }
      if (form.email == '') {
        required = false;
      } else {
        dataRes.email = form.email;
      }
      if (form.verifypass == '') {
        dataRes.verify_pass = 'Internal';
      } else {
        dataRes.verify_pass = form.verifypass;
      }
      if (form.verifypass == 'Internal') {
        if (form.password === '') {
          required = false;
        } else {
          dataRes.password = form.password;
        }
      }
      devLog('&&&', form.role);
      if (form.role == '') {
        dataRes.role = 'user';
      } else {
        dataRes.role = form.role;
      }
      if (required == false) {
        //loading='Заполните обязательные поля';
        setErrorPage('Заполните обязательные поля');
      }
      //------------------------
      devLog('dataRes', JSON.stringify(dataRes));
      devLog('required', required ? 'true' : 'false');
      if (required == true) {
        try {
          const res = await apiUsers(dataRes);
        } finally {
        }
      }
    },
    [apiUsers, setErrorPage],
  );
  useEffect(() => {
    CleanForm();
  }, []);
  useEffect(() => {
    CleanForm();
  }, [updatePage]);

  //-------------
  if (auth.accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  const formJson = [
    {
      form: 'input',
      type: 'text',
      label: 'Логин',
      name: 'login',
      value: '',
      required: true,
      helpingText: '',
    },
    {
      form: 'input',
      type: 'text',
      label: 'Имя',
      name: 'name',
      value: '',
      required: true,
      helpingText: '',
    },
    {
      form: 'input',
      type: 'text',
      label: 'Email',
      name: 'email',
      value: '',
      required: true,
      helpingText: '',
    },
    {
      form: 'select',
      label: 'Роль',
      name: 'role',
      value: 'user',
      required: true,
      helpingText: '',
      optionValues: userRoles,
    },
    {
      form: 'input',
      type: 'password',
      label: 'Пароль',
      name: 'password',
      value: '',
      required: true,
      helpingText: '',
    },
    {
      form: 'input',
      type: 'hidden',
      name: 'verifypass',
      value: 'Internal',
    },
  ];
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loading} />
      <UITypographyH1 pageCode={pageCode} loading={loading} />
      <div className="row">
        <div className="col-md-6 ">
          {' '}
          {/*offset-md-3*/}
          <div id="panel-1" className="panel">
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row  mb-60">
                  {/* mt-60 */}
                  <div className="col-12 ">
                    {/*=============================*/}
                    <FormGenerate
                      data={formJson}
                      fonClickSave={apiSave}
                      alert={{ loading: loading, error: error, success: success }}
                    />
                    {/*=============================*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
