import { useAuthContext } from 'shared/context/auth';
import { usePageContext } from 'shared/context/page';
import { dataDefault } from 'app/data/default';
import { ApiUserAuthCheck } from 'entities/auth/api';
import { localstoreDedault } from 'modules/localStorage/models';
import { useCallback, useEffect, useRef } from 'react';
import { devLog } from 'shared/lib/default';
import { ApiBypassGetStatus } from 'modules/bypass/api';

export const WAuth = () => {
  const storageName = dataDefault.storageName;
  const { globalBypassavail, setPageBypassavail, globalBypass, setPageBypass, systemName, setSystemName } = usePageContext();
  const { isAuthenticated, tokenCheck } = useAuthContext();
  const intervalRef = useRef<NodeJS.Timer>();
  //================================================  Auth
  const { response } = ApiUserAuthCheck();
  const check = useCallback(async () => {
    try {
      //-------
      const res = await response();
      //devLog('WAuth status chek', JSON.stringify(res));
      /*res.data = {"system":"ADF","users_total":68,"users_active":68,"users_inactive":0,"users_archived":0,"emerg_bypass_avail":true,"version":"0.0.25-7"}*/
      //------ pagecontext update
      if (globalBypassavail !== res.data.emerg_bypass_avail) {
        setPageBypassavail(res.data.emerg_bypass_avail);
      }
      if (systemName !== res.data.system) {
        setSystemName(res.data.system);
      }

      //------ localstore update
      const localstore = localStorage.getItem(storageName);
      const data = localstore ? JSON.parse(localstore) : localstoreDedault;
      data.version = res.data.version;
      data.bypassavail = res.data.emerg_bypass_avail;
      localStorage.setItem(storageName, JSON.stringify(data));
      //------
    } catch (e) {
      if (e instanceof Error) {
        devLog('', e.message);
      }
    }
  }, [response]);
  //================================================  Bypass
  const apiBypass = ApiBypassGetStatus();
  const getBypass = useCallback(async () => {
    try {
      const res = await apiBypass.response();
      const newbypass = res.data != null ? res.data.state : false;
      if (globalBypass != newbypass) {
        setPageBypass(newbypass);
      }
      //---
      /*
      const localbypass = SLocalstoreGetBypass();
      if (localbypass) {
        //setbypassavail(localbypass.bypassavail);
        if (localbypass.bypass !== newbypass) {
          //console.log('newbypass save', newbypass);
          SLocalstoreUpdate({ bypass: newbypass });
        }
      }
      */
    } catch (e) {
      if (e instanceof Error) {
        devLog('WBypassStatus', e.message);
      }
    }
  }, [response]);
  //================================================  Timer
  function StartTimer(isAuthenticated: boolean) {
    //devLog('StartTimer setInterval isAuthenticated', isAuthenticated ? 'true' : 'false');
    if (intervalRef.current !== undefined) {
      clearInterval(intervalRef.current as unknown as number); // Ensure proper type assertion
      intervalRef.current = undefined; // Clear the reference after clearing the interval
    }
    tokenCheck();
    if (isAuthenticated) {
      check();
      getBypass();
      intervalRef.current = setInterval(() => {
        //devLog('StartTimer setInterval ', 'ok');
        check();
        getBypass();
      }, 10000);
    }
  }

  //======
  useEffect(() => {
    StartTimer(isAuthenticated);
  }, [isAuthenticated]);
  return <></>;
};
