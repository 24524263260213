import { createContext, useContext, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { UIInfoNoAccess } from 'shared/ui/info';
import { AuthContext } from 'entities/users/AuthContext';
import { devLog } from 'shared/lib/default';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { WUsersListTable } from 'widgets/users/ListTable';
import { WUsersListFilter } from 'widgets/users/ListFilter';
import { noopVal } from 'shared/types';
import { InputNull, SelectNull } from 'shared/forms';
import { usePageContext } from 'shared/context/page';
import { UIAlertError } from 'shared/ui/alert';
import { usePagination } from 'shared/ui/Pagination';
import { ContextProviderUsersList, useContextUsersList } from 'entities/users/ContextList';
import { useAuthContext } from 'shared/context/auth';
//=============

//=============
export const UserListPage = () => {
  //=============
  const pageCode: string = 'users';
  //=============
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();

  const { loadingPage, errorPage } = usePageContext();

  //============= Form
  //----- Чтение параметров из url
  //console.log('location.pathname=',location.pathname);     // адрес текущей страницы для чтения параметров ?active=...
  const location = useLocation();
  const statusUrl = useRef('');
  const urlParamsActive = new URLSearchParams(location.search).get('active'); //USE ?active=on|off|archive
  if (urlParamsActive !== statusUrl.current && urlParamsActive != null) {
    devLog('URLSearchParams ', urlParamsActive);
    statusUrl.current = urlParamsActive;
  }

  /*
    if (roleUser.current !== "superadmin" && roleUser.current !== "admin" && roleUser.current !== "operator") {return <UIInfoNoAccess />}    */
  if (auth.accessRights(pageCode) !== true) {
    return <UIInfoNoAccess />;
  }
  //-----
  return (
    <>
      <ContextProviderUsersList>
        <UIBreadcrumbs pageCode={pageCode} loading={loadingPage} />
        <UITypographyH1 pageCode={pageCode} loading={loadingPage} />
        {errorPage && <UIAlertError text={errorPage} />}
        <div className="row">
          <div className="col-xl-12">
            <div id="panel-1" className="panel">
              <div className="panel-hdr">
                <h2>&nbsp;</h2>
                <div className="panel-toolbar">
                  <NavLink
                    to="/user/new"
                    className="btn mt-2 mb-2  btn-warning waves-effect waves-themed"
                  >
                    <span className="fal fa-plus mr-1"> Добавить</span>
                  </NavLink>
                </div>
              </div>
              <div className="panel-container show">
                <div className="panel-content">
                  <div className="panel-tag">
                    <WUsersListFilter />
                  </div>
                </div>
              </div>
            </div>
            <WUsersListTable />
          </div>
        </div>
      </ContextProviderUsersList>
    </>
  );
};
