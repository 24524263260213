export const dataDefault = {
  project: 'ADF',
  copyright: 'powersecurity',
  copyrightlink: 'https://powersecurity.org/ru',
  logoimg: '/assets/img/ADF1-min.svg',
  storageName: 'ADFuserData',
};

export const dataPageLimit = [10, 50, 100, 500];

export const dataPagesMenu: any = {
  dashboard: {
    name: 'Dashboard',
    url: '/',
    icon: 'fal fa-desktop',
    text: 'Статусы сервисов',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  users: {
    name: 'Пользователи',
    url: '/users',
    icon: 'fa-users',
    text: 'Список пользователей. Поиск. Редактирование. Методы линковки.',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'users.newadf': {
    name: 'Добавление пользователя ADF',
    url: '/user/new',
    icon: 'fa-users',
    text: 'Добавление нового локального пользователя ADF',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'users.update': {
    name: 'Редактирование пользователя',
    url: '/users',
    icon: 'fa-users',
    text: 'Редактирование атрибутов пользователя. Смена локального пароля ADF. Управление методами.',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'report.tg.notactivated': {
    name: 'Телеграм не активирован',
    url: '/report/not-activated',
    icon: 'fa-clipboard-list',
    text: 'Отчет. Список пользователей у которых телеграм не активирован.',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'report.tg.notlinked': {
    name: 'Телеграм не прилинкован',
    url: '/report/not-linked',
    icon: 'fa-clipboard-list',
    text: 'Отчет. Список пользователей у которых телеграм не прилинкован.',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'audit.daily': {
    name: 'Аудит логи за день',
    url: '/audit',
    icon: 'fa-ballot',
    text: 'Логи за 24 часа выбранной даты',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'audit.hour': {
    name: 'Аудит логи за час',
    url: '/audit',
    icon: 'fa-ballot',
    text: 'Логи за час от выбранной даты и времени',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'audit.period': {
    name: 'Аудит логи за период',
    url: '/audit',
    icon: 'fa-ballot',
    text: 'Логи за выбранный период',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'audit.config': {
    name: 'Аудит конфигурации',
    url: '/config',
    icon: 'fa-ballot',
    text: 'Конфигурация',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  audit: {
    name: 'Аудит',
    url: '/audit',
    icon: 'fal fa-users',
    text: '',
    accessRoles: ['superadmin', 'admin', 'auditor', 'operator'],
  },
  'settings.сonnectors.ldap.list': {
    name: 'LDAP коннектор',
    url: '/connectors',
    icon: 'fa-cogs',
    text: 'Список LDAP коннекторов',
    accessRoles: ['superadmin', 'admin', 'auditor'],
  },
  'settings.сonnectors.ldap.update': {
    name: 'Изменение LDAP коннектора',
    url: '/connectors',
    icon: 'fa-cogs',
    text: 'Тестирование коннектора после сохранения данных',
    accessRoles: ['superadmin', 'admin', 'auditor'],
  },
  'settings.сonnectors.ldap.listusers': {
    name: 'Пользователи LDAP коннектора',
    url: '/connectors',
    icon: 'fa-cogs',
    text: 'Список пользователей LDAP коннекторов',
    accessRoles: ['superadmin', 'admin', 'auditor'],
  },
  'settings.notification.email.servers.list': {
    name: 'Email сервера',
    url: '/settings/notification-email/servers/list',
    icon: 'fa-mail-bulk',
    text: 'Список серверов',
    accessRoles: ['superadmin', 'admin', 'auditor'],
  },
  'settings.notification.email.servers.new': {
    name: 'Добавить Email сервер',
    url: '/settings/notification-email/servers/new',
    icon: 'fa-mail-bulk',
    text: 'Новый',
    accessRoles: ['superadmin', 'admin', 'auditor'],
  },
  'settings.notification.email.servers.update': {
    name: 'Редактировать Email сервер',
    url: '/settings/notification-email/servers/update',
    icon: 'fa-mail-bulk',
    text: 'Редактировать',
    accessRoles: ['superadmin', 'admin', 'auditor'],
  },
  'settings.notification.email.templates.list': {
    name: 'Шаблоны',
    url: '/settings/notification-email/templates/list',
    icon: 'fa-envelope-open-text',
    text: 'Список шаблонов',
    accessRoles: ['superadmin', 'admin', 'auditor'],
  },
  'settings.notification.email.events.list': {
    name: 'События',
    url: '/settings/notification-email/events/list',
    icon: 'fa-envelope-square',
    text: 'Список событий',
    accessRoles: ['superadmin', 'admin', 'auditor'],
  },
  'settings.bypass': {
    name: 'Emergency bypass',
    url: '/settings/emergency-bypass',
    icon: 'fal fa-asterisk',
    text: 'Включение/выключение emergency bypass',
    accessRoles: ['superadmin', 'admin', 'operator'],
  },
  'test.audit.daily': {
    name: 'Test',
    url: '/test',
    icon: 'fal fa-asterisk',
    text: 'test',
    accessRoles: ['superadmin', 'admin', 'operator', 'auditor'],
  },
};

export const VerifySystem = [
  {
    name: 'ADF',
    value: 'Internal',
  },
  {
    name: 'Внешняя система',
    value: 'External',
  },
];

export interface IuserRoles {
  value: string;
  text: string;
}

// Первая роль используется по умолчанию = userRoles[0].value
export const userRoles: IuserRoles[] = [
  {
    value: 'user',
    text: 'Пользователь',
  },
  {
    value: 'operator',
    text: 'Оператор',
  },
  {
    value: 'auditor',
    text: 'Аудитор',
  },
  {
    value: 'admin',
    text: 'Админ',
  },
  {
    value: 'superadmin',
    text: 'Суперадмин',
  },
];
export const userRolesName = {
  user: 'Пользователь',
  operator: 'Оператор',
  auditor: 'Аудитор',
  admin: 'Админ',
  superadmin: 'Суперадмин',
};

export const userRolesActive: IuserRoles[] = [
  { value: '', text: 'все' },
  { value: 'on', text: 'активные' },
  { value: 'off', text: 'неактивные' },
  { value: 'archive', text: 'архивные' },
];
//   { value: 'offandarchive', text: 'неактивные + архивные' },

export const NEmailTransportType = [
  {
    value: 'SmtpInsecure',
    text: 'SMTP без ',
  },
  {
    value: 'SmtpStarttls',
    text: 'SMTP STARTTLS',
  },
  {
    value: 'SmtpTls',
    text: 'SMTP TLS',
  },
  {
    value: 'Postal',
    text: 'WebAPI',
  },
];
export const NEmailTLSversion = [
  {
    value: 'Tlsv10',
    text: 'TLS v1.0',
  },
  {
    value: 'Tlsv11',
    text: 'TLS v1.1',
  },
  {
    value: 'Tlsv12',
    text: 'TLS v1.2',
  },
  {
    value: 'Tlsv13',
    text: 'TLS v1.3',
  },
];
