import axios from 'axios';
import { useCallback, useState } from 'react';
import { CatchELog, devLogApi } from './default';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'shared/context/auth';

//----- Types
type ThttpHeaders = {
  Accept: string;
  'Content-type': string;
  Authorization?: string;
  'Access-Control-Allow-Origin'?: string;
};
type ThttpRequest = {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH';
  bodyJson?: {};
  params?: {};
};

export default axios.create({
  baseURL: 'http://localhost:3000',
  headers: {
    'Content-type': 'application/json',
  },
});
/**/
export const useAxiosAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  //const auth = useContext(AuthContext);

  const auth = useAuthContext();
  //---
  let myhead: ThttpHeaders = {
    Accept: 'application/json',
    'Content-type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*',
  };
  if (auth.token) {
    let bearer = `Bearer ${auth.token}`;
    myhead['Authorization'] = bearer;
  }
  const request = useCallback(
    async ({ url, method = 'GET', bodyJson = {}, params = {} }: ThttpRequest) => {
      setError(null);
      setLoading(true);
      try {
        let body = null;
        if (bodyJson)
          if (method !== 'GET' && JSON.stringify(bodyJson).length > 0) {
            body = JSON.stringify(bodyJson);
          }
        //---
        const responsehttp = await axios({
          method: method,
          headers: myhead,
          data: body,
          params: params,
          responseType: 'json',
          url,
        }).then((res) => {
          devLogApi('axios:::' + url, res); // лог всех статусов запросов
          //----
          if (res.status == 401) {
            auth.logout(); // разлогинить
            navigate('/'); // редирект
            throw new Error('Требуется аутентификация');
          }
          if (res.status == 403) {
            throw new Error('Нет прав');
          }
          if (res.status == 500) {
            throw new Error(res.statusText || 'Ошибка 500. Нет соединения с сервером.');
          }
          return res;
        });
        /*
                if (responsehttp.statusText !== 'OK') {
                    
                    console.log('http api ERROR: status=',responsehttp.status + ' statusText=' + responsehttp.statusText);
                    throw new Error(responsehttp.status + ' ' + responsehttp.statusText || 'Ошибка http запроса');
                }
                */
        setLoading(false);
        return responsehttp.data;
      } catch (e: any) {
        setLoading(false);
        setError(e.message);
        CatchELog(e, url);
        throw e;
      }
    },
    [auth],
  );
  return { loading, request, error };
};
