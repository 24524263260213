import { usePageContext } from 'shared/context/page';
import { useAuthContext } from 'shared/context/auth';
import { useEffect } from 'react';

export const WBypassAlert = () => {
  const { globalBypassavail, globalBypass } = usePageContext();

  /*
  function ShowinfoBypass() {
    const localbypass = SLocalstoreGetBypass();
    //console.log('menutop localbypass', localbypass.bypass);
    //console.log('menutop bypass', bypass);
    if (localbypass) {
      if (localbypass.bypass != bypass) {
        setbypass(localbypass.bypass);
        //bypass.current = localbypass.bypass;
        console.log('menutop bypass', bypass);
      }
    }
  }
  */

  //----
  useEffect(() => {
    //StartTimer(isAuthenticated);
  }, [globalBypassavail, globalBypass]);

  return (
    <>
      {globalBypassavail && (
        <>
          {globalBypass && (
            <>
              <div className="alert alert-danger alert-dismissible fade show alert-header mb-0">
                <div className="d-flex align-items-center">
                  <div className="alert-icon width-8">
                    <span className="icon-stack icon-stack-md">
                      <i className="base-2 icon-stack-3x color-danger-400"></i>
                      <i className="base-10 text-white icon-stack-1x"></i>
                      <i className="ni md-profile color-danger-800 icon-stack-2x"></i>
                    </span>
                  </div>
                  <div className="flex-1 pl-1 w-100">
                    <span className="h2">Emergency bypass active</span>
                    <small className="pl-2">
                      [ <i className="subheader-icon fal fa-cogs color-danger-800"></i>
                      Настройки /{' '}
                      <i className="subheader-icon fal fa-asterisk color-danger-800"></i> Emergency
                      bypass]
                    </small>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
