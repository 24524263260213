import moment from 'moment';
import { IBypassUser } from '../models';
interface IProps {
  users: IBypassUser[];
}
export function FVotedUsers(props: IProps) {
  return (
    <>
      <h3>Проголосовавшие</h3>
      <table className="table m-0">
        <thead>
          <tr>
            <th>#</th>
            <th>роль</th>
            <th>логин</th>
            <th>email</th>
            <th>вес</th>
            <th>дата</th>
          </tr>
        </thead>
        <tbody>
          {props.users.map((item, key) => {
            const time = new Date(Number(item.dtime.$date.$numberLong));
            let user = item.user.split('::');
            return (
              <tr key={key}>
                <th scope="row">{key + 1}</th>
                <td>{user[0]}</td>
                <td>{user[1]}</td>
                <td>{user[2]}</td>
                <td>
                  <b>{item.weight}</b>
                </td>
                <td>{moment(time).local().format('DD.MM.YYYY HH:mm:ss')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
