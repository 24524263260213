import { IAuditItem } from "entities/audit/models";
import moment from "moment";


type IAuditModalRightInfo={
    modalData:IAuditItem;
    dataTarget:string;
}

export function FAuditModalRightInfo({modalData,dataTarget}:IAuditModalRightInfo) {

    return (
<>
            {/*  Modal*/}
            <div className={`modal fade ${dataTarget}`} tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-right">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title h4">Детальная информация</h5>
                            {/*onClick={() => { setModalData({}); }} */}
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true"><i className="fal fa-times"></i></span>
                            </button>
                        </div>
                        <div className="modal-body audit-detail">
                            <table className="table table-bordered table-hover table-striped w-100">
                                <tbody>
                                    <tr>
                                        <td>time</td>
                                        <td>{moment(modalData.time).local().format('DD.MM.YYYY HH:mm:ss')}</td>
                                    </tr>
                                    <tr>
                                        <td>ID</td>
                                        <td>{modalData.ID}</td>
                                    </tr>
                                    <tr>
                                        <td>OUID</td>
                                        <td>{modalData.OUID}</td>
                                    </tr>
                                    <tr>
                                        <td>uname</td>
                                        <td>{modalData.uname}</td>
                                    </tr>
                                    <tr>
                                        <td>msg</td>
                                        <td>{modalData.msg}</td>
                                    </tr>
                                    <tr>
                                        <td>_id</td>
                                        <td>{modalData._id}</td>
                                    </tr>
                                    <tr>
                                        <td>addr</td>
                                        <td>{modalData.addr}</td>
                                    </tr>
                                    <tr>
                                        <td>class</td>
                                        <td>{modalData.class}</td>
                                    </tr>
                                    <tr>
                                        <td>facility</td>
                                        <td>{modalData.facility}</td>
                                    </tr>
                                    <tr>
                                        <td>geo</td>
                                        <td>{modalData.geo}</td>
                                    </tr>
                                    <tr>
                                        <td>level</td>
                                        <td>{modalData.level}</td>
                                    </tr>
                                    <tr>
                                        <td>module</td>
                                        <td>{modalData.module}</td>
                                    </tr>
                                    <tr>
                                        <td>req</td>
                                        <td>{modalData.req}</td>
                                    </tr>
                                    <tr>
                                        <td>res</td>
                                        <td>{modalData.res}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
</>

    );
}