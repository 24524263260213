import { useHttp } from "hooks/http.hooks";
import { useCallback, useState } from "react";
import { devLog } from "shared/lib/default";
import { DataHttp } from "shared/types";
import { IUsersNew } from "./models";
import { userRoles } from "app/data/default";
import { IUserItem } from "./models";
import { dataDefault } from "app/data/default";
import { useAxiosAuth } from "shared/lib/axios";

//================================
export const ApiReportNotActivated = () => {
    //----
    const url = '/api/user/report/telegram/not_activated';
    const textSuccess = ``;
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');
    //-------
    const response = useCallback(
        async () => {
            try {
                const dataList: DataHttp = await request(url);
                devLog(url, dataList.toString());
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                }
                setSuccess(textSuccess); devLog('success', textSuccess);
                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]
    );
    return { loading, response, error, success };
}
//====================
export const ApiReportNotLinked = () => {
    //----
    const url = '/api/user/report/telegram/not_linked';
    const textSuccess = ``;
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');
    //-------
    const response = useCallback(
        async () => {
            try {
                const dataList: DataHttp = await request(url);
                devLog(url, dataList.toString());
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                }
                setSuccess(textSuccess); devLog('success', textSuccess);
                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]
    );
    return { loading, response, error, success };
}

// CRUD Users = GET, POST, PUT/PATCH 
//-----  POST
export function ApiUsersGetListSearch() {
    //---
    const url: string = '/api/user/search';
    //const textSuccess = `Список постов успешно получен`;
    //---
    const [success, setSuccess] = useState('');
    const { loading, request, error } = useAxiosAuth();

    //---
    const response = useCallback(
        async (data: any) => {
            try {
                const res: any = await request({ url, method: 'POST', bodyJson: data });
                //setSuccess(textSuccess);
                return res;
            } finally { }
        }, [request]
    );
    return { loading, response, error, success };
}
 
 
//----- POST
//----- PUT/PATCH
//-----
interface IApiUserNew {
    userData: IUsersNew;
}
export const ApiUserNew = () => {
    const url: string = '/api/user/register';
    //----
    const [success, setSuccess] = useState('');
    const { loading, request, error } = useAxiosAuth();
    //const { loading, request, error } = useHttp();
    //const [list, setlist] = useState<IUserItem[]>([]);

    //---
    const response = useCallback(
        async (data: any) => {
            const textSuccess = `${data.login} добавлен `;
            //---
           
            var required = true;
             /*let dataRes: IUsersNew = { login: '', verify_pass: data.verify_pass };
            if (data.login == '') { required = false; } else { dataRes.login = data.login; }
            if (data.name == '') { required = false; } else { dataRes.name = data.name; }
            if (data.email == '') { required = false; } else { dataRes.email = data.email; }
            if (data.verify_pass == '') {
                dataRes.verify_pass = 'Internal';
            } else {
                dataRes.verify_pass = data.verify_pass;
            }
            if (data.verify_pass == 'Internal') {
                if (data.password == '') { required = false; } else { dataRes.password = data.password; }
            }
            if (data.verify_pass == 'External') {
                if (data.connector == '') {
                    required = false;
                } else {
                    dataRes.connector = data.connector;
                    dataRes.groups = data.groups;
                }
            }
            if (data.role == '') { dataRes.role = userRoles[0].value; }
            */
            //-----
            try {
                if (required == true) {
                    const res: any = await request({ url, method: 'POST', bodyJson: data });
                    setSuccess(textSuccess); devLog('success', textSuccess);
                    return res;
                } else {
                    return { data: [], total: 0, count: 0 }
                }
            } finally { }
        }, [request]
    );
    /*
        //-------===========================================
        const PostUserAdd = useCallback(
            async () => {
    
                //devLog('ApiUserNew dataArray',JSON.stringify(dataArray));
                try {
                    if (required == true) {
                        const dataList: DataHttp = await request(url, 'POST', dataArray);
                        devLog(url, dataList.toString());
                        if (error) {
                            devLog('error', error);
                            throw new Error(error || 'Ошибка http запроса');
                        }
                        setSuccess(textSuccess); devLog('success', textSuccess);
                        return dataList;
                    } else {
                        return { data: [], total: 0, count: 0 }
                    }
                } catch (e: any) {
                    devLog(url, e.name + ' ' + e.message);
                    throw e;
                }
    
            }, [request]
            
        );*/
    // return { loading, PostUserAdd, error, success };
    return { loading, response, error, success };
}

export const ApiUserTgEmailSend = () => {
    //const url: string = '/api/user/:uid/methods/telegram/activate';
    //----
    const [success, setSuccess] = useState('');
    const { loading, request, error } = useAxiosAuth();
    //---
    const response = useCallback(
        async (uid: any) => {
            const textSuccess = `email отправлен`;
            //---
            var required = true;
           //---
            try {
                if (required == true) {
                    const res: any = await request({url:`/api/user/${uid}/methods/telegram/activate`, method: 'GET'});
                    setSuccess(textSuccess); devLog('success', textSuccess);
                    
                    return res;
                } else {}
            } finally { }
        }, [request]
    );
    return { loading, response, error, success };
}
//----- 
//----- 




//=====================================================
export const ApiUsersGetDataID = (user_id: string) => {
    //----
    const url = '/api/user/' + user_id;
    const textSuccess = `Данные пользователея ${user_id} получены`;
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');

    //-------
    const GetUserData = useCallback(
        async () => {
            try {
                const dataList: DataHttp = await request(url);
                devLog(url, dataList.toString());
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                }
                setSuccess(textSuccess); devLog('success', textSuccess);
                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]
    );
    return { loading, GetUserData, error, success };
}
/*
export function UserNewCheck(userData: IUsersNew){
    var required = true;
    const dataArray: any = {};
    if (userData.login == '') { required = false; } else { dataArray.login = userData.login; }
    if (userData.name == '') { required = false; } else { dataArray.name = userData.name; }
    if (userData.email == '') { required = false; } else { dataArray.email = userData.email; }
    if (userData.verify_pass == '') { dataArray.verify_pass = 'Internal';}
    if (userData.verify_pass == 'Internal') {
        if (userData.password == '') { required = false; } else { dataArray.password = userData.password; }
    }
    if (userData.verify_pass == 'External') {
        if (userData.connector == '') { required = false; } else { dataArray.connector = userData.connector; }
    }
    if (userData.role == '') { userData.role = userRoles[0].value; }
    return {required,dataArray}
}
*/

/*
ub struct UserSearchQuery {
    #[serde(rename = "_id", serialize_with = "serialize_object_id_opt")]
    docid: Option<ObjectId>,
    login: Option<String>,
    fuzzy_query: String,
    name: Option<String>,
    email: Option<String>,
    role: Option<String>,
    active: Option<bool>,
    archive: Option<bool>,    
//    date_created: bson::DateTime,
    page: u8,
    limit: u8,
}
*/

type TUserSearch = {
    docid?: string;
    login?: string;
    fuzzy_query?: string;
    name?: string;
    email?: string;
    role?: string;
    active?: boolean;
    archive?: boolean;
    page?: number;
    limit?: number;
}
export const ApiUserSearch = (userData: TUserSearch) => {
    //---- '/api/user/search', 'POST', { 'page': page, 'limit': limit, 'fuzzy_query': fuzzyquery }
    const url = '/api/user/search';
    const textSuccess = `Пользователь ${userData.login} найден`;
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');

    //-------
    const UserSearchData = useCallback(
        async () => {
            const dataArray: any = {};
            //--- required 
            if (userData.page) { dataArray.page = userData.page; } else { dataArray.page = 1; }
            if (userData.limit) { dataArray.limit = userData.limit; } else { dataArray.limit = 1; }
            if (userData.fuzzy_query) { dataArray.fuzzy_query = userData.fuzzy_query; } else { dataArray.fuzzy_query = ''; }
            //--- not required
            if (userData.docid) if (userData.docid != '') { dataArray.docid = userData.docid; }
            if (userData.login) if (userData.login != '') { dataArray.login = userData.login; }
            if (userData.name) if (userData.name != '') { dataArray.name = userData.name; }
            if (userData.email) if (userData.email != '') { dataArray.email = userData.email; }
            if (userData.role) if (userData.role != '') { dataArray.role = userData.role; }
            if (userData.active) { dataArray.active = userData.active; }
            if (userData.archive) { dataArray.archive = userData.archive; }
            //---
            try {
                const dataList: DataHttp = await request(url, 'POST', dataArray);
                devLog("UserSearchData " + url, JSON.stringify(dataList));
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                }
                if (dataList.total > 0) {
                    setSuccess(textSuccess); devLog('success', textSuccess);
                }
                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]
    );
    return { loading, UserSearchData, error, success };
}


export const ApiUserUpdate = (user_id: string, userData: IUserItem) => {
    const url = '/api/user/' + user_id + '/update';
    const textSuccess = `Данные обновлены`;
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');
    //-------
    const UserUpdate = useCallback(
        async () => {
            const dataArray: any = {};
            //--- required 
            /*
            if (userData.page) { dataArray.page = userData.page; } else { dataArray.page = 1; }
            if (userData.limit) { dataArray.limit = userData.limit; } else { dataArray.limit = 1; }
            if (userData.fuzzy_query) { dataArray.fuzzy_query = userData.fuzzy_query; } else { dataArray.fuzzy_query = ''; }
            //--- not required
            if (userData.docid) if (userData.docid != '') { dataArray.docid = userData.docid; }
            */
            if (userData.login) if (userData.login != '') { dataArray.login = userData.login; }
            if (userData.name) if (userData.name != '') { dataArray.name = userData.name; }
            if (userData.email) if (userData.email != '') { dataArray.email = userData.email; }
            if (userData.role) if (userData.role != '') { dataArray.role = userData.role; }
            if (userData.active) { dataArray.active = userData.active; }
            if (userData.archive) { dataArray.archive = userData.archive; }
            if (userData.methods) { dataArray.methods = userData.methods; }
            if (userData.connector) { dataArray.connector = userData.connector; }
            if (userData.verify_pass) { dataArray.verify_pass = userData.verify_pass; }
            if (userData.groups) { dataArray.groups = userData.groups; }

            //---
            try {
                const dataList: DataHttp = await request(url, 'PUT', dataArray);
                devLog("UserSearchData " + url, JSON.stringify(dataList));
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                } else {
                    setSuccess(textSuccess); devLog('ApiUserUpdate success ', textSuccess);
                }




                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]
    );
    return { loading, UserUpdate, error, success };




    //=======
    /*
    try {
        const data: DataHttp= await request(url, 'PATCH', dataArray);
        return data;
    } catch (e: any) {
        devLog(url, e.name + ' ' + e.message);
        throw e;
    }
    */
}



export const ApiUserActivate = (user_id: string) => {
    const url = '/api/user/' + user_id + '/activate';
    const textSuccess = 'Активирован';
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');
    //-------
    const Activate = useCallback(
        async () => {
            try {
                const dataList = await request(url, 'PATCH', {});
                devLog("ApiUserChangeActive " + url, JSON.stringify(dataList));
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                }
                if (dataList.total > 0) {
                    setSuccess(textSuccess); devLog('success', textSuccess);
                }
                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]);
    return { loading, Activate, error, success };
}
export const ApiUserDeactivate = (user_id: string) => {
    const url = '/api/user/' + user_id + '/deactivate';
    const textSuccess = 'Деактивирован';
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');
    //-------
    const Deactivate = useCallback(
        async () => {
            try {
                const dataList = await request(url, 'PATCH', {});
                devLog("ApiUserChangeActive " + url, JSON.stringify(dataList));
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                }
                setSuccess(textSuccess); devLog('success', textSuccess);
                if (dataList.total > 0) { }
                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]);
    return { loading, Deactivate, error, success };
}