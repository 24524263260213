import { useLocation, useNavigate } from "react-router-dom";
import { usePageContext } from "shared/context/page";
import { FormInput, FormPreventDefault, FormSelect } from "shared/ui/form";
import { userRoles, userRolesActive } from "../../app/data/default";
import { FormDefaltUserList, useContextUsersList } from "entities/users/ContextList";
import { useEffect, useRef } from "react";
import { devLog } from "shared/lib/default";

export function WUsersListFilter() {
    //-----
    const navigate = useNavigate(); // редирект
    const { refreshPage} = usePageContext();
    const {form,setForm,saveInput,saveSelect,page,setPage}=useContextUsersList();

    //----- Очистка фильтра
    const CleanFilter = () => {
        navigate('/user'); // редирект = очистить параметры url?action=...
        setForm(FormDefaltUserList);
        setPage(1);
        refreshPage();
    }
    //============= Кнопка поиска
    const ButtonSearch = () => {

        setPage(1);
        refreshPage();
    }

//============= 
    return (
        <>
            <form onSubmit={FormPreventDefault}>
                <div className="row">
                    <div className="col-xl-3">
                        <FormInput label="Поиск"
                            name="fuzzyquery"
                            value={form.fuzzyquery}
                            helpingText="Поиск по полям: Логин, Имя Фамилия, Email"
                            fOnChange={saveInput}
                        />
                    </div>
                    <div className="col-xl-2">
                        <FormSelect label="Статус" name="active" value={form.active} fOnChange={saveSelect} optionValues={userRolesActive} />
                    </div>
                    <div className="col-xl-2">
                        <FormSelect label="Роль" name="role" value={form.role} fOnChange={saveSelect} optionValues={[{ value: '', text: 'все' }, ...userRoles]} />
                    </div>
                    <div className="col-xl-3 pt-lable ">
                        <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-themed "
                            onClick={ButtonSearch}
                        >Применить </button>
                    </div>
                    <div className="col-xl-2 pt-lable text-right ">
                        <button
                            type="button"
                            className="btn btn-light waves-effect waves-themed mr-3"
                            onClick={CleanFilter}
                        >Очистить <span className="fal fa-brush ml-1" data-fa-transform="rotate-180"></span>  </button>
                    </div>
                </div>
            </form>
        </>
    );
}