// import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../entities/users/AuthContext';
import { dataDefault } from '../app/data/default';
// import { SLocalstoreGetBypass } from 'shared/lib/localstore';
import { useAuthContext } from 'shared/context/auth';
import { usePageContext } from 'shared/context/page';

export function MenuTop() {
  const { logout, userlogin, userrole } = useAuthContext();
  const { systemName } = usePageContext();
  const navigate = useNavigate();

  //const location = useLocation();
  // console.log(location.pathname); // адрес текущей страницы

  const logoutHandler = (event: React.FormEvent) => {
    event.preventDefault();
    logout();
    navigate('/'); // редирект
    // navigate(-1) - вернуться назад
  };

  return (
    <>
      <header className="page-header" role="banner">
        <div className="page-logo">
          <a href="#!" className="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
            <img src="/assets/img/logo.png" alt={dataDefault.project} aria-roledescription="logo" />
            <span className="page-logo-text mr-1">{dataDefault.project}</span>
            <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
            <i className="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
          </a>
        </div>

        <div className="hidden-lg-up">
          <a href="#" className="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
            <i className="ni ni-menu"></i>
          </a>
        </div>
        <div className="search color-primary-900">
          <h3>
            {/*  <i className="fal fa-city color-primary"></i> &nbsp;  
            <i className="fal fa-container-storage"></i>&nbsp;
            <i className="fal fa-cloud"></i>&nbsp;
            <i className="fal fa-building"></i>&nbsp;
            <i className="fal fa-server"></i>&nbsp; */}
            <i className="fal fa-server"></i> &nbsp; {systemName}
          </h3>
        </div>
        <div className="ml-auto d-flex">
          <div className="hidden-sm-up">
            <a href="#" className="header-icon" data-action="toggle" data-class="mobile-search-on" data-focus="search-field" title="Search">
              <i className="fal fa-search"></i>
            </a>
          </div>

          <div>
            <a href="#" data-toggle="dropdown" title="" className="header-icon d-flex align-items-center justify-content-center ml-2">
              <img src="/assets/img/avatar-admin.png" className="profile-image rounded-circle" alt="" />
            </a>
            <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
              <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                  <span className="mr-2">
                    <img src="/assets/img/avatar-admin.png" className="rounded-circle profile-image" alt=" " />
                  </span>
                  <div className="info-card-text">
                    <div className="fs-lg text-truncate text-truncate-lg">
                      {userlogin} {userrole}
                    </div>
                    <span className="text-truncate text-truncate-md opacity-80">{userrole}</span>
                  </div>
                </div>
              </div>

              <div className="dropdown-divider m-0"></div>
              <a className="dropdown-item fw-500 pt-3 pb-3" href="!#" onClick={logoutHandler}>
                <span data-i18n="drpdwn.page-logout">Logout</span>
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
