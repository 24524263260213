import { PageProvider } from 'shared/context/page';
import { AuthProvider } from 'shared/context/auth';
import { Pages } from 'pages';

function App() {
  //--------
  /*
  const {
    ready,
    token,
    tokenCheck,
    login,
    logout,
    userId,
    userlogin,
    userrole,
    language,
    version,
    accessRights,
  } = useAuth();
 
  // const isAuthenticated = !!token; //  boolean значение токена - если есть - аутентифицирован
  const isAuthenticated = token != '' ? true : false;
  //const routes = useRoutes(isAuthenticated);
 
  const pages = usePagesRoutes(isAuthenticated);

  //=============
  if (!ready) {
    return <UIInfoLoader />;
  }
  */
  //==========

  return (
    <>
      <PageProvider>
        <AuthProvider>
          <Pages />
          {/*
          <AuthContext.Provider
            value={{
              ready,
              token,
              tokenCheck,
              login,
              logout,
              userId,
              isAuthenticated,
              userlogin,
              userrole,
              language,
              version,
              accessRights,
            }}
          >
            {isAuthenticated && (
              <>
                <WAuth />
                <div className="page-wrapper">
                  <AuthTimer />
                  <div className="page-inner">
                    <MenuLeft />
                    <div className="page-content-wrapper">
                      <MenuTop />
                      <WBypassAlert />
                      <main id="js-page-content" role="main" className="page-content">
                        {pages}
                      </main>
                      <div
                        className="page-content-overlay"
                        data-action="toggle"
                        data-class="mobile-nav-on"
                      ></div>
                      <footer className="page-footer" role="contentinfo">
                        <div className="d-flex align-items-center flex-1 text-muted">
                          <span className="hidden-md-down fw-700">
                            2023 © {dataDefault.copyright}{' '}
                          </span>
                        </div>
                        <div>
                          version {version}
                           
                        </div>
                      </footer>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!isAuthenticated && <>{pages}</>}
          
          </AuthContext.Provider>*/}
        </AuthProvider>
      </PageProvider>
    </>
  );
}

export default App;
