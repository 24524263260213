import { useCallback, useEffect } from 'react';
import { ApiBypassGetVoteDisable } from '../api';
import { devLog } from 'shared/lib/default';
import { UIAlertError, UIAlertErrorSM, UIAlertSuccess, UIAlertSuccessSM } from 'shared/ui/alert';
import { usePageContext } from 'shared/context/page';
import { UIInfoLoader } from 'shared/ui/info';

export const WBypassVoteDisable = () => {
  const { refreshPage } = usePageContext();
  const { loading, response, error, success } = ApiBypassGetVoteDisable();
  //=============

  const dataList = useCallback(async () => {
    try {
      const res = await response();
      devLog('data', JSON.stringify(res.data));
      refreshPage();
    } catch (e) {
      if (e instanceof Error) {
        devLog('WBypassVoteDisable', e.message);
      }
    }
  }, [response]);

  const ButtonClick = () => {
    dataList();
  };
  //=============

  //=============

  return (
    <>
      <div className="row">
        <div className="col-xl-6">
          <button
            className="btn btn-primary waves-effect waves-themed  mr-2"
            onClick={ButtonClick}
            disabled={loading}
          >
            Отключить {loading && <UIInfoLoader />}
          </button>
        </div>
        <div className="col-xl-6">
          {error && <UIAlertErrorSM text={error} />}
          {/*success && <UIAlertSuccessSM text={success} />*/}
        </div>
      </div>
    </>
  );
};
