import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { UIInfoNoAccess } from 'shared/ui/info';
import MUIDataTable from 'mui-datatables';
import { IAuditItem } from 'entities/audit/models';
import { usePageContext } from 'shared/context/page';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { UIAlertError } from 'shared/ui/alert';
import { ApiReportNotLinked } from 'entities/users/api';
import { useAuthContext } from 'shared/context/auth';
registerLocale('ru', ru);

export function ReportPageNotLinked() {
  //=====
  const pageCode: string = 'report.tg.notlinked';
  const { updatePage, setLoadingPage, setErrorPage, loadingPage, errorPage } = usePageContext();
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const [list, setList] = useState<IAuditItem[]>([]);

  //============= API
  const { loading: loadingApiList, response: apiList, error: errorApiList } = ApiReportNotLinked(); //  API
  const getList = useCallback(async () => {
    try {
      const res = await apiList(); // API
      if (res.data) {
        setList(changeData(res.data));
      }
    } finally {
    }
  }, [apiList]);
  //=============
  function changeData(list: IAuditItem[]) {
    let data: any = [];
    list.map((item: any) => {
      item.link_valid = moment(item.link_valid).local().format('YYYY.MM.DD HH:mm:ss');
      console.log(item.link_valid);
      data.push(item);
    });
    return data;
  }
  function makeData() {
    let data: any = [];
    list.map((item: any) => {
      item.info = '';
      data.push(item);
    });
    return data;
  }
  //=============
  useEffect(() => {
    setLoadingPage(loadingApiList);
  }, [loadingApiList, setLoadingPage]);
  useEffect(() => {
    setErrorPage(errorApiList);
  }, [errorApiList, setErrorPage]);
  //--- page, limit
  useEffect(() => {
    getList();
  }, [updatePage]);
  //=============
  /*
"user_id": "64be6409e6662161bb193d76",
            "username": "zajtseva_es@open.ru",
            "tg_id": 0,
            "link_state": false,
            "link_valid": 1690309846879
*/
  const columns1 = [
    {
      name: 'username',
      label: 'username',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'email',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'tg_id',
      label: 'tg_id',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: 'nowrap', paddingLeft: '30px' } }),
        customBodyRender: (value: any) => (
          <>
            {' '}
            {value != 0 && <>value</>}
            {value == 0 && <>N/A</>}
          </>
        ),
      },
    },
    {
      name: 'link_state',
      label: 'link_state',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: 'nowrap', paddingLeft: '30px' } }),
        customBodyRender: (value: any) => (
          <>
            {value && <>true</>}
            {!value && <>false</>}
          </>
        ),
      },
    },
    {
      name: 'link_valid',
      label: 'link_valid',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'user_id',
      label: 'open user',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value: any) => (
          <a
            className="btn btn-outline-primary  waves-effect waves-themed"
            target="_blank"
            href={'/user/update/' + value}
          >
            <i className="fal fa-external-link-alt"></i>
          </a>
        ),
      },
    },
  ];
  /*
  {
      name: 'link_valid',
      label: 'link_valid',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }),
        customBodyRender: (value: any) => (
          <>{moment(value).local().format('DD.MM.YYYY HH:mm:ss')}</>
        ),
      },
    },
  
  */
  //---
  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 500],
    textLabels: {
      body: {
        noMatch: 'За выбранный период данных нет',
        toolTip: 'Sort',
      },
      pagination: {
        next: 'Следующая',
        previous: 'Предыдущая',
        rowsPerPage: 'На странице:',
        displayRows: 'of',
      },
      toolbar: {
        search: 'Поиск',
        downloadCsv: 'Скачать CSV',
        print: 'Печать',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'Все',
        title: 'Фильтры',
        reset: 'Сбросить',
      },
      viewColumns: {
        title: 'Показать колонки',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
    downloadOptions: {
      filename: 'tg_not_linked.csv',
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    selectableRowsHideCheckboxes: true,
  };
  const dataTable = useMemo(() => makeData(), [list]);

  if (auth.accessRights(pageCode) !== true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loadingPage} />
      <UITypographyH1 pageCode={pageCode} loading={loadingPage} />
      {errorPage && <UIAlertError text={errorPage} />}

      <MUIDataTable
        title={'ТГ не прилинкован'}
        data={dataTable}
        columns={columns1}
        options={options}
      />
    </>
  );
}
