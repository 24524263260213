import { Link, useLocation } from "react-router-dom";

 

export function FNotificationEmailNav() {
    const location = useLocation();
    const arrPath = location.pathname.split('/');

    return (<>
        <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <Link to="/settings/notification-email/servers/list" className={(arrPath[3]=='servers' || arrPath[3]== '')?'nav-link active':'nav-link'} >Email сервера</Link>
                </li>
                <li className="nav-item">
                    <Link to="/settings/notification-email/templates/list" className={arrPath[3]=='templates'?'nav-link active':'nav-link'} >Шаблоны</Link>
                </li>
                <li className="nav-item">
                    <Link to="/settings/notification-email/event/list" className={arrPath[3]=='events'?'nav-link active':'nav-link'} >События</Link>
                </li>
            </ul>
        </div>
    </>);
}