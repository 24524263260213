import { useEffect, useState } from 'react';
import { IWUsersUpdateProfile } from '../models';
import { useHttp } from 'hooks/http.hooks';
import { LANG_default } from 'app/data/lang';
import { UIAlertErrorSM, UIAlertSuccessSM } from 'shared/ui/alert';
import { usePageContext } from 'shared/context/page';
import { UIInfoLoader } from 'shared/ui/info';

export function WUsersUpdateTimeLocked({ userdata }: IWUsersUpdateProfile) {
  const language = 'ru';
  const { refreshPage } = usePageContext();
  const { loading, request, error } = useHttp();
  const [msgsuccess, setMsgSuccess] = useState('');
  const [msgerror, setMsgError] = useState('');
  const FunctionUpdate = async () => {
    try {
      setMsgError('');
      setMsgSuccess('');
      // PATCH  /api/user/:uid/unlock
      const data = await request('/api/user/' + userdata._id + '/unlock', 'PATCH', {});
      setMsgSuccess('Временная блокировка снята');
      refreshPage();
    } catch (e) {
      console.log(e);
      setMsgError(LANG_default[language].error500);
    }
  };
  useEffect(() => {
    setMsgError('');
    setMsgSuccess('');
  }, [userdata]);
  return (
    <>
      <tr>
        <td>Ошибки входа:</td>
        <td>
          {loading && (
            <>
              <UIInfoLoader />
            </>
          )}

          {userdata.auth_failures == null && <>Нет</>}
          {userdata.auth_failures && <>{userdata.auth_failures ? userdata.auth_failures : 0}</>}
        </td>
        <td className="text-right">{userdata.auth_locked == true && <>{userdata.auth_locked ? <span className="text-danger">&nbsp;временно заблокирован</span> : ''}</>}</td>
        <td className="text-right">
          {(msgsuccess || error || msgerror) && (
            <>
              {msgsuccess && <UIAlertSuccessSM text={msgsuccess} />}
              {error && <UIAlertErrorSM text={error} />}
              {msgerror && <UIAlertErrorSM text={msgerror} />}
            </>
          )}
          {userdata.auth_locked == true && (
            <>
              <button type="button" className=" btn btn-sm btn-outline-success waves-effect waves-themed" onClick={FunctionUpdate}>
                <span className="fas fa-archive mr-1"></span>
                Разблокировать
              </button>
            </>
          )}
        </td>
      </tr>
    </>
  );
}
