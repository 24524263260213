import { useCallback, useContext, useEffect, useState } from 'react';
import { usePagination } from '../../shared/ui/Pagination';
import { usePageContext } from 'shared/context/page';
import { ApiUsersGetListSearch } from 'entities/users/api';
import { useUserModalRightInfo } from 'features/users/ui/ModalRightInfo';
import { IUserItem } from 'entities/users/models';
import { UIInfoNoData } from 'shared/ui/info';
import { EUserUIShowActive, EUserUIShowGroups } from 'entities/users/ui';
import { userRolesName } from '../../app/data/default';
import { DataFromJsonByKey, devLog } from 'shared/lib/default';
import { NavLink } from 'react-router-dom';
import { useContextUsersList } from 'entities/users/ContextList';

export function WUsersListTable() {
  const { updatePage, setLoadingPage, setErrorPage } = usePageContext();
  const { form, setForm, saveInput, saveSelect, page, setPage } = useContextUsersList();
  const {
    pageNumber,
    changePageTotal,
    changePageCount,
    pageLimit,
    PaginationComponent,
    PaginationSelectLimit,
    setPageNumber,
  } = usePagination();
  const { UserModalRightInfoButton, UserModalRightInfo } = useUserModalRightInfo();
  //======
  //============= userlist
  const [userlist, setUserlist] = useState<IUserItem[]>([]);
  //============= API
  const {
    loading: loadingApiList,
    response: apiUsersList,
    error: errorApiList,
  } = ApiUsersGetListSearch(); //  API
  //----- API request apiUsersList
  const getListSearch = useCallback(async () => {
    try {
      if (pageNumber != page) {
        setPageNumber(page);
      }
      devLog('form.active', form.active);
      // dataReq - массив данных фильтра для запроса
      let dataReq: any = { page: page, limit: pageLimit, fuzzy_query: form.fuzzyquery.trim() };
      if (form.active === 'on') {
        dataReq.active = true;
      }
      if (form.active === 'off') {
        dataReq.active = false;
      }
      if (form.active === 'offandarchive') {
        dataReq.active = false;
        dataReq.archive = true;
      }
      if (form.active === 'archive') {
        dataReq.archive = true;
      }
      if (form.role !== '') {
        dataReq.role = form.role;
      }

      //==========
      const res = await apiUsersList(dataReq); // POST LIST
      //console.log('!!!!!!');
      //console.log(res);
      if (res.data) {
        setUserlist(res.data);
        changePageTotal(res.total);
        changePageCount(res.count);
        //devLog('res.data=', res.data);
      } else {
        changePageTotal(0);
        changePageCount(0);
      }
    } finally {
    }
  }, [apiUsersList, pageLimit, form, changePageCount, changePageTotal, page]);
  //============= return
  const TableHeader = () => {
    return (
      <tr>
        <th className="text-center">Статус</th>
        <th className="text-center">Архив</th>
        <th>Логин</th>
        <th>Имя Фамилия</th>
        <th>Email</th>
        <th>Роль</th>
        <th className="text-center">Группы</th>
        <th className="text-center">...</th>
        <th className="text-center">...</th>
      </tr>
    );
  };
  //============= отслеживание
  useEffect(() => {
    setLoadingPage(loadingApiList);
  }, [loadingApiList, setLoadingPage]);
  useEffect(() => {
    setErrorPage(errorApiList);
  }, [errorApiList, setErrorPage]);
  //--- page, limit
  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber]);
  useEffect(() => {
    getListSearch();
  }, [updatePage, page]);
  return (
    <>
      <div id="panel-1" className="panel">
        <div className="panel-container show">
          <div className="panel-content">
            <div className="row">
              <div className="col-md-2">
                <PaginationSelectLimit />
              </div>
              <div className="col-md-10">
                <PaginationComponent />
              </div>
            </div>
            {!userlist.length && <UIInfoNoData />}
            {userlist.length > 0 && (
              <>
                <table
                  id="dt-basic-example"
                  className="table table-bordered table-hover table-striped w-100"
                >
                  <thead className="bg-primary-600">
                    <TableHeader />
                  </thead>
                  <tbody>
                    {userlist.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{EUserUIShowActive(item.active)}</td>
                          <td className="text-center">
                            {!item.archive ? (
                              <i className="fal fa-archive text-secondary  "></i>
                            ) : (
                              <i className="fas fa-archive text-danger"></i>
                            )}
                          </td>
                          <td>{item.login || null}</td>
                          <td>{item.name || null}</td>
                          <td>{item.email || null}</td>
                          <td>
                            {item.role &&
                              userRolesName &&
                              DataFromJsonByKey({ code: item.role, json: userRolesName })}
                          </td>
                          <td className="text-center">{EUserUIShowGroups(item.groups)}</td>
                          <td className="text-center">
                            <UserModalRightInfoButton item={item} />
                          </td>
                          <td className="text-center">
                            <NavLink
                              to={'/user/update/' + item._id}
                              className="btn  btn-outline-primary waves-effect waves-themed"
                            >
                              <i className="fal fa-pen"></i>
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <TableHeader />
                  </tfoot>
                </table>
              </>
            )}
            <PaginationComponent />
          </div>
        </div>
      </div>
      <UserModalRightInfo />
    </>
  );
}
