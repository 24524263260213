import { useAuthContext } from 'shared/context/auth';
import { usePagesRoutes } from './routes';
import { UIInfoLoader } from 'shared/ui/info';
import { dataDefault } from 'app/data/default';
import { WAuth } from 'modules/auth/widgets/WAuth';
import { MenuLeft } from 'widgets/MenuLeft';
import { MenuTop } from 'widgets/MenuTop';
import { WBypassAlert } from 'modules/bypass/widgets/WBypassAlert';
import { useEffect } from 'react';

export const Pages = () => {
  const { ready, isAuthenticated, version, tokenCheck } = useAuthContext();
  const pages = usePagesRoutes(isAuthenticated);
  useEffect(() => {
    tokenCheck();
  }, []);
  //=============

  if (!ready) {
    return <UIInfoLoader />;
  }

  return (
    <>
      <WAuth />
      {isAuthenticated && (
        <>
          <div className="page-wrapper">
            <div className="page-inner">
              <MenuLeft />
              <div className="page-content-wrapper">
                <MenuTop />
                <WBypassAlert />
                <main id="js-page-content" role="main" className="page-content">
                  {pages}
                </main>
                <div className="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div>
                <footer className="page-footer" role="contentinfo">
                  <div className="d-flex align-items-center flex-1 text-muted">
                    <span className="hidden-md-down fw-700">
                      © 2023 - {new Date().getFullYear()} {dataDefault.copyright}
                    </span>
                  </div>
                  <div>version {version}</div>
                </footer>
              </div>
            </div>
          </div>
        </>
      )}
      {!isAuthenticated && <>{pages}</>}
    </>
  );
};
