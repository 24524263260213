import React, { useCallback, useState } from 'react';
import { useHttp } from 'hooks/http.hooks';
import { ICLDAPItem } from './models';
import { devLog } from 'shared/lib/default';
import { DataHttp } from 'shared/types';

export interface DataHttpConnector {
  data: ICLDAPItem;
  count: number;
  total: number;
}
export const ApiCLDAPGetConnector = (connector_id: string) => {
  //----
  const url = '/api/ldap/' + connector_id + '';
  const textSuccess = `Данные коннектора ${connector_id} получены`;
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');
  //-------
  const CLDAPGetConnector = useCallback(async () => {
    try {
      const dataList: DataHttpConnector = await request(url);
      devLog(url, JSON.stringify(dataList));
      if (error) {
        devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      devLog('success', textSuccess);
      return dataList;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);
  return { loading, CLDAPGetConnector, error, success };
};

// GET
export const ApiCLDAPGetList = () => {
  //----
  const url = '/api/ldap/connectors';
  const textSuccess = 'Список коннекторов получен';
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');

  //-------
  const CLDAPGetList = useCallback(async () => {
    try {
      const dataList: DataHttp = await request(url);
      devLog(url, dataList.data.toString());
      if (error) {
        devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      devLog('success', textSuccess);
      return dataList;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);
  return { loading, CLDAPGetList, error, success };
};

export const ApiCLDAPGetListUsers = (connector_id: string) => {
  //----
  const url = '/api/ldap/' + connector_id + '/users';
  const textSuccess = `Список пользвателей для коннектора ${connector_id} получен`;
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');

  //-------
  const CLDAPGetListUsers = useCallback(async () => {
    try {
      const dataList: DataHttp = await request(url);
      devLog(url, dataList.toString());
      if (error) {
        devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      devLog('success', textSuccess);
      return dataList;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);
  return { loading, CLDAPGetListUsers, error, success };
};
