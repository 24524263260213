import { useHttp } from 'hooks/http.hooks';
import { useCallback, useState } from 'react';
import { devLog } from 'shared/lib/default';

// Проверка аутентификации каждые 10 секунд useHttp fetch
export const ApiUserAuthCheck = () => {
  //----
  const url = '/api/status';
  const textSuccess = '';
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');

  //-------
  const response: any = useCallback(async () => {
    try {
      const res: any = await request(url);
      //devLog(url, res.data.toString());
      if (res.data.result == 'error') {
        //devLog('res.data.result', res.data.result);
        throw new Error(res.data.error || 'Ошибка http запроса');
      }
      if (error) {
        //devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      //devLog('success', textSuccess);
      return res;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);
  return { loading, response, error, success };
};
