import moment from "moment";

// Information block with the current date and time of data update.
export function UIdateUpdate() {
    const dateUpdate=moment().local().toDate();
    return (
        <>
            <div>
                <i className="fal fa-sync fw-900 mr-2" aria-hidden="true"></i>  {moment(dateUpdate).format('DD.MM.YYYY HH:mm:ss')}
            </div>
        </>

    )
}
// Information block with a message that there are no access rights.
export function UIInfoNoAccess() {
    return (
        <>
            <div>
                Недостаточно прав
            </div>
        </>

    )
}

export function UIInfoLoader() {
    return (
        <>
           <span className="fal fa-sync fs-xl fa-spin" > </span>
        </>
    )
}
// 
 
type TText={
    text?:string;
}
export function UIInfoNoData({text='Данных нет'}:TText) {
    return (
            <div className="line-top-dotted">
                {text}
            </div>
    )
}

 