import { dataPageLimit } from 'app/data/default';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormPreventDefault } from './form';

export const usePagination = () => {
  //============= page
  const [pageNumber, setPageNumber] = useState<number>(1);
  const changePageNumber = useCallback(
    (page: any) => {
      let PageNumberN = Number(page);
      setPageNumber(PageNumberN);
    },
    [setPageNumber],
  );

  //============= pageTotal
  const [pageTotal, setPageTotal] = useState<number>(0);
  const changePageTotal = useCallback((value: number) => {
    setPageTotal(value);
  }, []);
  //============= pageCount
  const [pageCount, setPageCount] = useState<number>(0);
  const changePageCount = useCallback((value: number) => {
    setPageCount(value);
  }, []);
  //============= pageLimit
  const [pageLimit, setLimit] = useState<number>(50);
  const changePageLimit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let numberlimit = Number(event.target.value);
    setPageNumber(1);
    setLimit(numberlimit);
  };
  // ============ pageInput
  const pageNumberInput = useRef<string>('');
  const [pageNumberError, setPageNumberError] = useState<string>('');

  /** Функция проверяет сроку - должны быть все цифры */
  function containsOnlyDigits(input: string): boolean {
    return /^\d+$/.test(input);
  }

  const changePageNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    pageNumberInput.current = event.target.value;
  };
  const savePageInput = () => {
    if (!containsOnlyDigits(pageNumberInput.current)) {
      setPageNumberError('должно быть число ');
      return;
    }
    const page = Number(pageNumberInput.current);
    const pagesNumber = Math.ceil(pageTotal / pageLimit);
    if (page > pagesNumber) {
      setPageNumberError('превысило ' + pagesNumber);
      return;
    }
    if (page <= 0) {
      setPageNumberError('минимум 1 ');
      return;
    }
    setPageNumberError('');
    setPageNumber(page);
  };
  //============= functions
  function PaginationSelectLimit() {
    return (
      <form onSubmit={FormPreventDefault}>
        <div className="form-group">
          <label className="form-label" htmlFor="limit">
            на странице
          </label>
          <select className="form-control" id="limit" name="limit" onChange={changePageLimit} defaultValue={pageLimit}>
            {dataPageLimit.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </form>
    );
  }
  function PaginationInputPageNumber() {
    return (
      <div className="pagination-input">
        {pageTotal / pageLimit > 20 && (
          <form onSubmit={FormPreventDefault}>
            <div className="form-group">
              <label className="form-label" htmlFor="limit">
                К cтранице
              </label>

              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  defaultValue={pageNumberInput.current}
                  onChange={(e) => {
                    changePageNumberInput(e);
                    e.preventDefault();
                  }}
                />
                <button className="btn btn-warning waves-effect waves-themed input-group-append" onClick={savePageInput}>
                  Go
                </button>
                <span className="help-block color-danger-900">{pageNumberError}</span>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
  function PaginationComponent() {
    const result = [];
    const countMath = Math.ceil(pageTotal / pageLimit);
    const pagination = Array(8).fill(0);
    if (pageCount > 0) {
      pagination[1] = 1;
      let max = countMath;
      if (countMath > 5) {
        max = 5;
        pagination[7] = countMath;
      }

      if (pageNumber <= 4) {
        if (pageNumber != 1) {
          pagination[pageNumber - 1] = pageNumber - 1;
        }

        for (let number = 1; number <= max; number++) {
          pagination[number] = number;
        }
        if (pageNumber != 1) {
          pagination[pageNumber - 1] = pageNumber - 1;
        }
        pagination[pageNumber] = pageNumber;

        /*
      if (pageNumber != countMath) { pagination[pageNumber + 1] = pageNumber + 1; }
      pagination[2] = 2;
      pagination[3] = 3;
      pagination[4] = 4;
      */
      }
      if (pageNumber >= countMath - 3 && pageNumber > 4 && countMath > 4) {
        pagination[3] = countMath - 4;
        pagination[4] = countMath - 3;
        pagination[5] = countMath - 2;
        pagination[6] = countMath - 1;
      }
      if (pageNumber < countMath - 3 && pageNumber > 4 && countMath > 4) {
        pagination[3] = pageNumber - 1;
        pagination[4] = pageNumber;
        pagination[5] = pageNumber + 1;
      }

      for (let number = 1; number <= 7; number++) {
        if (pagination[number] != 0) {
          result.push(
            <li className={pagination[number] === pageNumber ? 'page-item active' : 'page-item'} key={number + Math.random()}>
              <a
                className="page-link"
                onClick={(e) => {
                  changePageNumber(pagination[number]);
                  e.preventDefault();
                }}
                href="!#"
              >
                {pagination[number]}
              </a>
            </li>,
          );
        }
        if (pagination[number] == 0 && countMath > 6) {
          result.push(
            <li className="page-item" key={number + Math.random()}>
              <span className="page-link">...</span>
            </li>,
          );
        }
      }
    }
    /*
        for (let number = 1; number <= countMath; number++) {
          if (number == 2 && pageNumber != 2 && (pageNumber - 1) != 2 && number != pageNumber && number - 1 != pageNumber) {
            result.push(<li className="page-item" key={number}>...</li>)
          }
          if (number == 1 || number - 1 == pageNumber || number == pageNumber || number + 1 == pageNumber || number == countMath) {
            result.push(
              <li className={number === pageNumber ? 'page-item active' : 'page-item'} key={number}>
                <a className="page-link" onClick={(e) => { changePageNumber(number); e.preventDefault(); }} href="!#"  >{number}</a>
              </li>
            )
          }
    
          if (countMath - 1 == number && (pageNumber + 1) != countMath - 1 && countMath - 1 != pageNumber && number != pageNumber) {
            result.push(<li className="page-item" key={number}>...</li>)
          }
        }
        if (countMath > pageNumber) {
          result.push(
            <li className="page-item">
              <a className="page-link" onClick={(e) => { changePageNumber(pageNumber + 1); e.preventDefault(); }} >
                <i className="fal fa-chevron-right"></i></a>
            </li>);
        }
         */
    return (
      <>
        <div className=" pagination-mt">
          <ul className="pagination" key={Math.random()}>
            {result}
          </ul>
        </div>
      </>
    );
  }
  useEffect(() => {
    pageNumberInput.current = String(pageNumber);
    setPageNumberError('');
  }, [pageNumber]);
  //=============
  return { pageNumber, changePageNumber, setPageNumber, pageTotal, changePageTotal, pageCount, changePageCount, pageLimit, changePageLimit, PaginationComponent, PaginationSelectLimit, PaginationInputPageNumber };
};

// USE
// <Pagination limit={limit} selected={page} total={pagetotal} pcount={pagecount} paginate={paginate} />
interface IPaginationProps {
  selected: number;
  limit: number;
  total: number;
  pcount: number;
  paginate: (number: number) => void;
}

export function Pagination(props: IPaginationProps) {
  const result = [];

  //console.log(JSON.stringify(props.item));

  for (let number = 1; number <= Math.ceil(props.total / props.limit); number++) {
    result.push(
      <li className={number === props.selected ? 'page-item active' : 'page-item'} key={number}>
        <button
          className="page-link"
          onClick={(e) => {
            props.paginate(number);
            e.preventDefault();
          }}
        >
          {number}
        </button>
      </li>,
    );
  }
  if (props.pcount > props.limit) {
    result.push(
      <li className="page-item" key={Math.random()}>
        <button
          className="page-link"
          onClick={(e) => {
            e.preventDefault();
            props.paginate(props.selected + 1);
          }}
        >
          <i className="fal fa-chevron-right"></i>
        </button>
      </li>,
    );
  }

  return (
    <>
      <div className=" mt-xl-5">
        <ul className="pagination">{result}</ul>
      </div>
    </>
  );
}
