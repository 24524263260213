import { ICLDAPItem } from 'modules/connectorLDAP/models';
import { Link } from 'react-router-dom';
type TFCLDAPAutoProvision = {
  item: ICLDAPItem;
};

export function FCLDAPAutoProvision({ item }: TFCLDAPAutoProvision) {
  return (
    <>
      <div className="row mb-2">
        <div className="col-6">
          <h5>Aвтоимпорт пользователей </h5>
          <div>[ обновление/ деактивация/ архивация ]</div>
        </div>
        <div className="col-6 text-right">
          <Link className="btn btn-warning bg-warning-100 btn-icon waves-effect waves-themed" to={'/settings/connector-ldap-update/' + item._id}>
            <i className="fal fa-pen "></i>
          </Link>
        </div>
      </div>
      <table className="table mt-3">
        <tbody>
          <tr>
            <td className="align-top col-3  ">По расписанию:</td>
            <td className="text-left">
              {item.config != null && (
                <table className="table table-bordered table-hover table-striped mb-0 ">
                  <tbody>
                    {item.config.auto_provision_schedule == undefined && (
                      <tr>
                        <td colSpan={2} className="col-12">
                          Отключен
                        </td>
                      </tr>
                    )}
                    {item.config.auto_provision_schedule != undefined && item.config.auto_provision_schedule != null && (
                      <>
                        <tr>
                          <td colSpan={2} className="col-12">
                            <i className={item.config.auto_provision_schedule ? 'fas fa-circle  text-success' : 'fas fa-circle  text-danger'}></i> Включен
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>Импорт:</b>
                          </td>
                          <td>{item.config.auto_provision_schedule.import == true ? 'включен' : 'отключен'}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Деактивация/архивация:</b>
                          </td>

                          <td>{item.config.auto_provision_schedule.deactivate == true ? 'включен' : 'отключен'}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="row mb-2">
        <div className="col-6">
          <h5>Селфсервис </h5>
        </div>
        <div className="col-6 text-right">
          <Link className="btn btn-warning bg-warning-100 btn-icon waves-effect waves-themed" to={'/settings/connector-ldap-update/' + item._id}>
            <i className="fal fa-pen "></i>
          </Link>
        </div>
      </div>
      {item.config != null && (
        <table className="table table-bordered table-hover table-striped mb-0 ">
          <tbody>
            {item.config.auto_provision_schedule == undefined && item.config.auto_provision_schedule != null && (
              <tr>
                <td>Статус</td>
                <td className="col-12">Отключен</td>
              </tr>
            )}

            <>
              <tr>
                <td>Статус</td>
                <td>
                  <i className={item.config.chpass_enabled ? 'fas fa-circle  text-success' : 'fas fa-circle  text-danger'}></i> {item.config.chpass_enabled == true ? 'включен' : 'отключен'}
                </td>
              </tr>
              {item.config.chpass_helper_group_dn != undefined && (
                <tr>
                  <td>
                    <b>Helper group:</b>
                  </td>
                  <td>{item.config.chpass_helper_group_dn}</td>
                </tr>
              )}
            </>
          </tbody>
        </table>
      )}
    </>
  );
}
