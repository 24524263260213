import { ApiUserDeactivate } from "entities/users/api";
import { useCallback, useState } from "react";
import { devLog } from "shared/lib/default";
import { UIAlertError, UIAlertSuccessSM } from "shared/ui/alert";

type IUserActiveButton = {
    user_id: string;
    active: boolean;
}
export function FUserActiveButton({ user_id, active }: IUserActiveButton) {
    const [showButton, setShowButton] = useState(true);

    //---
    const { loading, Deactivate, error, success } = ApiUserDeactivate(user_id);
    //---
    const Button = useCallback(
        async () => {
            try {
                const res = await Deactivate();
                setShowButton(false);

            } catch (e) { if (e instanceof Error) { devLog('catch e ', e.message); } }
        }, [Deactivate]
    );

    //---
    return (
        <>
            {error && <UIAlertError text={error} />}
            {success && <UIAlertSuccessSM text={success} />}
            {showButton && 
            <div className="row col-ldap-buttons">
                <div className="col-2 align-self-center">
                    {active && <><i className="fas fa-lightbulb text-success"></i></>}
                </div>
                <div className="col-10">
                    <button
                        className={`btn btn-block btn-outline-${active == true ? 'danger' : 'success'} waves-effect waves-themed mr-1`}
                        onClick={Button}
                        disabled={loading}
                    >Деактивировать </button>
                    
                </div>
            </div>
        }

        </>
    );
}