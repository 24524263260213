import { useCallback, useState } from "react";
import { useAxiosAuth } from "shared/lib/axios";
import { devLog } from "shared/lib/default";
import { INEmailServersNew } from "./models";
import { useHttp } from "hooks/http.hooks";
import { DataHttp } from "shared/types";

/*
/api/config/mailer

pub fn mailer_router() -> Router {
    Router::new()
    .route("/", post(mailer::create_handler))
    .route("/list", get(mailer::list_handler))
    .route("/:id", get(mailer::get_handler))
    .route("/:id", patch(mailer::update_handler))
    .route("/:id", delete(mailer::delete_handler))

}
*/


interface IApiNEmailServersNew {
    userData: INEmailServersNew;
}
export const ApiNEmailServersNew = () => {
    const url: string = '/api/config/mailer/';
    //----
    const [success, setSuccess] = useState('');
    const { loading, request, error } = useAxiosAuth();
    //---
    const response = useCallback(
        async (data: any) => {
            const textSuccess = `${data.login} добавлен `;
            //---
            var required = true;
           //---
            try {
                if (required == true) {
                    const res: any = await request({ url, method: 'POST', bodyJson: data });
                    setSuccess(textSuccess); devLog('success', textSuccess);
                    return res;
                } else {
                    return { data: [], total: 0, count: 0 }
                }
            } finally { }
        }, [request]
    );
    return { loading, response, error, success };
}

export function ApiNEmailServersList() {
    //---
    const url: string = '/api/config/mailer/list';
    const textSuccess = `Список постов успешно получен`;
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');

    //---
    const response = useCallback(
        async () => {
            try {
                const dataList: DataHttp = await request(url);
                devLog(url, dataList.toString());
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                }
                setSuccess(textSuccess); devLog('success', textSuccess);
                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]
    );
    return { loading, response, error, success };
}