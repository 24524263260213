import { useHttp } from "hooks/http.hooks";
import moment from "moment";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { UIAlertError } from "shared/ui/alert";
import { UIInfoLoader } from "shared/ui/info";
interface IFConnectorLDAP {
    id: string;
}

// get /api/ldap/:connectorid/test
// {"result":"SUCCESS","count":59}
export function FConnectorLDAP({ id }: IFConnectorLDAP) {
    const { loading, request, error, success } = useHttp();
    const [date, setDate] = useState(moment().local().toDate());
    const [count, setCount] = useState(-1);
    const getTest = useCallback(
        async () => {
            try {
                const data = await request(`/api/ldap/${id}/test`);
                if (data) {
                    console.log('/api/ldap/:connectorid/test', data);
                    if (data.result == "SUCCESS") {
                        setCount(data.count);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }, [request]
    );
    const ButtonRequest = () => {
        getTest();
        setDate(moment().local().toDate());
    }
    return (
        <>
            <div className="row mb-2">
                <div className="col-6  "> <h4>Соединение</h4></div>
                <div className="col-6  text-right">
                    <button className="btn btn-warning bg-warning-100 waves-effect waves-themed mb-1" onClick={ButtonRequest}>
                        Тестировать соединение {loading && <UIInfoLoader />}
                    </button>
                </div>
            </div>
            {error && <UIAlertError text={error} />}
            <table className="table mt-3">
                <tbody>
                    <tr >
                        <td className="align-middle">
                            Время: {count >= 0 ? moment(date).local().format('DD.MM.YYYY HH:mm:ss') : ''}
                        </td>
                        <td className="align-middle">
                            Пользователи: {count >= 0 && <span className="badge   badge-primary  ">{count}</span>}
                        </td>
                        <td className="text-right">
                            {count > 0 &&
                                <Link className="btn  btn-outline-success waves-effect waves-themed" to={"/settings/connector-ldap-users/" + id} >
                                    <span className="fal fa-plus mr-1"><i className="fal fa-user mr-1" /></span>
                                    Проверить и загрузить
                                </Link>
                            }
                        </td>

                    </tr>
                </tbody>
            </table>
        </>
    )
}