interface InumberLong {
  $numberLong: string;
}
interface Idtime {
  $date: InumberLong;
}

export interface IBypassUser {
  user: string;
  weight: number;
  dtime: Idtime;
}

interface IBypassStatusData {
  doctype: string;
  state: boolean;
  vote_for: number;
  voted_enable: IBypassUser[];
  voted_disable: IBypassUser[];
  voting_expire: string | null;
}
export interface IBypassStatus {
  result: string;
  data: IBypassStatusData;
}

export const BypassStatusDefault = {
  result: '',
  data: {
    doctype: 'bypass_state',
    state: false,
    vote_for: 0,
    voted_enable: [],
    voted_disable: [],
    voting_expire: null,
  },
};

export interface IBypassVoteEnable {}
export interface IBypassRevokeEnable {}
export interface IBypassVoteDisable {}
export interface IBypassRevokeDisable {}
