import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { UIInfoLoader, UIInfoNoAccess } from 'shared/ui/info';
import MUIDataTable from 'mui-datatables';
import { IAuditItem } from 'entities/audit/models';
import { FAuditModalRightInfo } from 'features/audit/ui/ModalRightInfo';
import { usePageContext } from 'shared/context/page';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { UIAlertError } from 'shared/ui/alert';
import { ApiAuditPostListHour } from 'entities/audit/api';
import { useAuthContext } from 'shared/context/auth';
registerLocale('ru', ru);

export function AuditListHour() {
  //=====
  const pageCode: string = 'audit.hour';
  const { updatePage, setLoadingPage, setErrorPage, loadingPage, errorPage } = usePageContext();
  //=====

  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //---------

  //const { loading, request, error } = useHttp();
  const [auditlist, setAuditlist] = useState<IAuditItem[]>([]);
  const [modalData, setModalData] = useState<any>({});

  //============= date
  var today = new Date();
  today.setHours(today.getHours() - 1);

  //===

  const [date1, setDate1] = useState(moment().local().subtract(1, 'hour').toDate()); //today  .toLocaleDateString('ru')

  //============= form
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
  };
  //============= API request

  const {
    loading: loadingApiList,
    response: apiList,
    error: errorApiList,
  } = ApiAuditPostListHour(); //  API
  const getListHour = useCallback(async () => {
    try {
      const searchdate = moment(date1).unix() * 1000; //
      // dataReq - массив данных фильтра для запроса
      let dataReq: any = { searchdate: searchdate };

      //==========
      const res = await apiList(dataReq); // POST LIST
      if (res.data) {
        setAuditlist(res.data);
      }
    } finally {
    }
  }, [apiList, date1]);
  //=============
  const SearchHandler = async () => {
    try {
      getListHour();
    } catch (e) {
      console.log(e);
    }
  };
  function OpenModalInfo(item: {}) {
    setModalData(item);
    return true;
  }
  function makeData() {
    let data: any = [];
    auditlist.map((item: any) => {
      //item.time = moment(item.time).local().format('DD.MM.YYYY HH:mm:ss');
      /*
            item.info = () => (
                <button className="btn btn-outline-primary  waves-effect waves-themed"
                    data-toggle="modal" data-target=".default-example-modal-right"
                    onClick={() => OpenModalInfo(item)}
                >
                    <i className="fal fa-info"></i>
                </button>
            );
            */
      item.info = '';
      data.push(item);
    });
    return data;
  }

  //============= отслеживание
  useEffect(() => {
    setLoadingPage(loadingApiList);
  }, [loadingApiList, setLoadingPage]);
  useEffect(() => {
    setErrorPage(errorApiList);
  }, [errorApiList, setErrorPage]);
  //--- page, limit
  useEffect(() => {
    getListHour();
  }, [updatePage]);

  const columns1 = [
    {
      name: 'time',
      label: 'Время',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }),
        customBodyRender: (value: any) => (
          <>{moment(value).local().format('DD.MM.YYYY HH:mm:ss')}</>
        ),
      },
    },
    {
      name: 'ID',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'OUID',
      label: 'OUID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'uname',
      label: 'uname',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'msg',
      label: 'msg',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'geo',
      label: 'geo',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'level',
      label: 'level',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'req',
      label: 'req',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'res',
      label: 'res',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'info',
      label: 'info',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value: any, tableMeta: any) => (
          <button
            className="btn btn-outline-primary  waves-effect waves-themed"
            data-toggle="modal"
            data-target=".default-example-modal-right"
            onClick={() => {
              console.log(tableMeta);
              OpenModalInfo(tableMeta.tableData[tableMeta.rowIndex]);
            }}
          >
            <i className="fal fa-info"></i>
          </button>
        ),
      },
    },
  ];
  //---
  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 500],
    textLabels: {
      body: {
        noMatch: 'За выбранный период данных нет',
        toolTip: 'Sort',
      },
      pagination: {
        next: 'Следующая',
        previous: 'Предыдущая',
        rowsPerPage: 'На странице:',
        displayRows: 'of',
      },
      toolbar: {
        search: 'Поиск',
        downloadCsv: 'Скачать CSV',
        print: 'Печать',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'Все',
        title: 'Фильтры',
        reset: 'Сбросить',
      },
      viewColumns: {
        title: 'Показать колонки',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
    downloadOptions: {
      filename: 'audithour.csv',
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    selectableRowsHideCheckboxes: true,
  };

  const dataTable = useMemo(() => makeData(), [auditlist]);

  //============= return

  //if (auth.userrole != "superadmin" && auth.userrole != "admin" && auth.userrole != "auditor") {return <UIInfoNoAccess />}
  if (auth.accessRights(pageCode) !== true) {
    return <UIInfoNoAccess />;
  }
  //console.log('typeof auth.userrole', typeof auth.userrole);
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loadingPage} />
      <UITypographyH1 pageCode={pageCode} loading={loadingPage} />
      {errorPage && <UIAlertError text={errorPage} />}

      <div className="row">
        <div className="col-xl-12">
          <div id="panel-1" className="panel">
            <div className="panel-container show">
              <div className="panel-content">
                <div className="panel-tag">
                  <form onSubmit={submitHandler}>
                    <div className="row mb-3">
                      <div className="col-xl-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="date1">
                            Дата и время [dd.mm.yyyy hh:mm]
                          </label>
                          {/*<input onInput={changeDate1} name="date1" id="date1" className="form-control" type="text" defaultValue={date1}  />*/}{' '}
                          {/* type="datetime-local"  defaultValue={date1}*/}
                          <DatePicker
                            className="form-control"
                            locale="ru"
                            showTimeSelect
                            selected={date1}
                            onChange={(val: any) => setDate1(val)}
                            dateFormat="dd.MM.yyyy HH:mm"
                          />
                        </div>
                      </div>
                      <div className="col-xl-3  ">
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-themed "
                          onClick={SearchHandler}
                          disabled={loadingApiList}
                        >
                          Применить {loadingApiList && <UIInfoLoader />}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* !auditlist.length && <><div className="text-center">Данных нет</div></> */}

      <MUIDataTable
        title={'Аудит логи за час'}
        data={dataTable}
        columns={columns1}
        options={options}
      />
      {modalData && (
        <FAuditModalRightInfo modalData={modalData} dataTarget="default-example-modal-right" />
      )}
      {/*  Modal
            <div className="modal fade default-example-modal-right" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-right">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title h4">Детальная информация</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setModalData({}); }}>
                                <span aria-hidden="true"><i className="fal fa-times"></i></span>
                            </button>
                        </div>
                        <div className="modal-body audit-detail">
                            <table className="table table-bordered table-hover table-striped w-100">
                                <tbody>
                                    <tr>
                                        <td>time</td>
                                        <td>{modalData.time}</td>
                                    </tr>
                                    <tr>
                                        <td>ID</td>
                                        <td>{modalData.ID}</td>
                                    </tr>
                                    <tr>
                                        <td>OUID</td>
                                        <td>{modalData.OUID}</td>
                                    </tr>
                                    <tr>
                                        <td>uname</td>
                                        <td>{modalData.uname}</td>
                                    </tr>
                                    <tr>
                                        <td>msg</td>
                                        <td>{modalData.msg}</td>
                                    </tr>
                                    <tr>
                                        <td>_id</td>
                                        <td>{modalData._id}</td>
                                    </tr>
                                    <tr>
                                        <td>addr</td>
                                        <td>{modalData.addr}</td>
                                    </tr>
                                    <tr>
                                        <td>class</td>
                                        <td>{modalData.class}</td>
                                    </tr>
                                    <tr>
                                        <td>facility</td>
                                        <td>{modalData.facility}</td>
                                    </tr>
                                    <tr>
                                        <td>geo</td>
                                        <td>{modalData.geo}</td>
                                    </tr>
                                    <tr>
                                        <td>level</td>
                                        <td>{modalData.level}</td>
                                    </tr>
                                    <tr>
                                        <td>module</td>
                                        <td>{modalData.module}</td>
                                    </tr>
                                    <tr>
                                        <td>req</td>
                                        <td>{modalData.req}</td>
                                    </tr>
                                    <tr>
                                        <td>res</td>
                                        <td>{modalData.res}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>*/}
    </>
  );
}
