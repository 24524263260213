import { Keys } from 'shared/types';
//======================= выводит в консоль в режиме разработки
export function devLog(name: string, text: string) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(name, text);
  }
}
// res - responce api -
export function devLogApi(text: string, res: any) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    //console.log(text,'status:'+res.status+' '+res.statusText);
    //console.log(text,'data:'+JSON.stringify(res.data));
  }
}
// USE  CatchELog(e,url);
export function CatchELog(e: any, pageCode: string) {
  if (e instanceof Error) {
    devLog(pageCode, e.message);
  }
}

/*
type TUITypographyH1={
    pageCode:string;
}
interface Keys {
    key: string;
}
const obj1: Keys = {key: pageCode};
const dataPage =dataPagesMenu[obj1.key as keyof typeof dataPagesMenu];

*/
// получение данных из Json по ключу с преобразованием типов
type TDataFromJsonByKey = {
  code: string;
  json: {};
};
/*USE
DataFromJsonByKey({code:'',json:{}})
*/
export function DataFromJsonByKey({ code, json }: TDataFromJsonByKey) {
  const obj1: Keys = { key: code };
  const data = json[obj1.key as keyof typeof json];
  return data;
}
