
export function EUserUIShowActive(active: boolean | undefined) {
    return (
        <>
            <span className="d-none">{active ? 1 : 0}</span>
            {active ? <i className="fas fa-lightbulb text-success"></i> : <i className="fal fa-lightbulb text-secondary"></i>}
        </>
    );
}
export function EUserUIShowGroups(groups: string[] | undefined) {
    let count = 0;
    if (groups) if (groups.length > 0) { count = groups.length; }
    return (<span className={count > 0 ? '' : 'text-secondary'}>{count}</span>);
}
 


type TEUsersGroups = {
    index: number;
    login: string;
    groups: string[]|undefined;
}

export function EUsersGroupsModal({ index, login, groups }: TEUsersGroups) {
    return (
        <>{groups && <>
            <button type="button" className="btn btn-outline-primary  waves-effect waves-themed" data-toggle="modal" data-target={'#modal' + index} >
                {/*<i className="fal fa-ellipsis-h"></i> */}
                Группы <span className="  badge bg-primary-300 ml-2">{groups.length}</span>
            </button>
            {/*   <!-- Modal on -->*/}
            <div className="modal fade" id={'modal' + index} tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                Пользователь: {login}
                                 
                            </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="fal fa-times"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h3>Группы</h3>
                            {groups.map((itemGroup, indexGroup) => {
                                return (<div key={indexGroup}>= {itemGroup}<br /></div>);
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/*   <!-- Modal off -->*/}
            </>}
        </>
    );
}