import { dataDefault, dataPagesMenu } from 'app/data/default';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { noop } from 'shared/types';

function login_null(token: string, userId: string, userlogin: string, userrole: string, version: string) {}
function accessRights_null(pageCode: string) {
  return false;
}

export const AuthContext = createContext({
  ready: false,
  token: '',
  tokenCheck: noop,
  userId: '',
  userlogin: '',
  userrole: 'user',
  login: login_null,
  logout: noop,
  isAuthenticated: false,
  language: '',
  version: '',
  accessRights: accessRights_null,
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: any) => {
  const storageName = dataDefault.storageName;
  const [ready, setReady] = useState(false);
  const [token, setToken] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [userlogin, setUserLogin] = useState<string>('');
  const [userrole, setUserRole] = useState('user');
  const [language, setLanguage] = useState('ru');
  const [version, setVersion] = useState('');
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const setAuthisAuth = (val: boolean) => setisAuthenticated(val);

  //===============
  const navigate = useNavigate();

  //===========================
  const login = useCallback(
    (jwtToken: string, id: string, ulogin: string, urole: string, version: string) => {
      setReady(false);
      console.log('login id', id);
      if (jwtToken && id && ulogin && urole) {
        setToken(jwtToken);
        setUserId(id);
        setUserLogin(ulogin);
        setUserRole(urole);
        setVersion(version);
        setLanguage('ru');
        setisAuthenticated(true);
        //---

        localStorage.setItem(
          storageName,
          JSON.stringify({
            userId: id,
            token: jwtToken,
            userlogin: ulogin,
            userrole: urole,
            version: version,
            bypass: false,
            bypassavail: false,
          }),
        );
      } else {
      }
      setReady(true);
      return;
    },
    [storageName],
  );
  //===========================
  const logout = useCallback(() => {
    setToken('');
    setUserId('');
    setUserLogin('');
    setUserRole('user');
    localStorage.removeItem(storageName);
    setisAuthenticated(false);
    navigate('/'); // редирект
  }, [navigate, storageName]);
  //===========================
  const tokenCheck = useCallback(() => {
    setReady(false);
    const localStore = localStorage.getItem(storageName);
    if (localStore) {
      const data = JSON.parse(localStore);
      if (data && data.token !== '') {
        login(data.token, data.userId, data.userlogin, data.userrole, data.version);
        setReady(true);
        return true;
      } else {
        logout();
        setReady(true);
        return false;
      }
    }
    setReady(true);
    return false;
  }, [login, logout, storageName]);
  //==============================
  const accessRights = useCallback(
    (pageCode: string) => {
      let result = false;
      const { accessRoles } = dataPagesMenu[pageCode];
      accessRoles.map((item: string) => {
        if (item === userrole[0]) {
          result = true;
        }
      });
      return result;
    },
    [userrole],
  );
  //================
  useEffect(() => {}, []);
  return (
    <AuthContext.Provider
      value={{
        ready,
        token,
        tokenCheck,
        login,
        logout,
        userId,
        isAuthenticated,
        userlogin,
        userrole,
        language,
        version,
        accessRights,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
