import React, { useEffect, useRef, useState } from 'react';
import { dataPageLimit } from 'app/data/default';
import { observer } from 'mobx-react-lite';
import pageStore from '../../../app/store/pageStore.mobx';
// <WPaginationBasic />

export const WPaginationBasic: React.FC = observer(() => {
  const pageNumberInput = useRef<string>('');
  const [pageNumberError, setPageNumberError] = useState<string>('');
  //------ Limit
  function PaginationSelectLimit() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="form-group">
          <label className="form-label" htmlFor="limit">
            на странице
          </label>
          <select className="form-control" id="limit" name="limit" onChange={changePageLimit} defaultValue={pageStore.paginationLimit}>
            {dataPageLimit.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </form>
    );
  }
  const changePageLimit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let numberlimit = Number(event.target.value);
    pageStore.setPaginationLimit(numberlimit);
  };
  //------ Input
  /** Функция проверяет сроку - должны быть все цифры */
  function containsOnlyDigits(input: string): boolean {
    return /^\d+$/.test(input);
  }
  const changePageNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    pageNumberInput.current = event.target.value;
  };
  const savePageInput = () => {
    if (!containsOnlyDigits(pageNumberInput.current)) {
      setPageNumberError('должно быть число ');
      return;
    }
    const page = Number(pageNumberInput.current);
    const pagesNumber = Math.ceil(pageStore.paginationTotalItem / pageStore.paginationLimit);
    if (page > pagesNumber) {
      setPageNumberError('превысило ' + pagesNumber);
      return;
    }
    if (page <= 0) {
      setPageNumberError('минимум 1 ');
      return;
    }
    setPageNumberError('');
    pageStore.setSelectedPage(page);
  };
  function PaginationInputPageNumber() {
    return (
      <div className="pagination-input">
        {pageStore.paginationTotalItem / pageStore.paginationLimit > 20 && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="form-group">
              <label className="form-label" htmlFor="limit">
                К cтранице
              </label>

              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  defaultValue={pageStore.paginationSelected}
                  onChange={(e) => {
                    e.preventDefault();
                    changePageNumberInput(e);
                  }}
                />
                <button className="btn btn-warning waves-effect waves-themed input-group-append" onClick={savePageInput}>
                  Go
                </button>
                <span className="help-block color-danger-900">{pageNumberError}</span>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
  //---------------
  function PaginationComponent() {
    const result = [];
    const countMath = Math.ceil(pageStore.paginationTotalItem / pageStore.paginationLimit);
    let pageNumber = pageStore.paginationSelected;
    let pageCount = pageStore.paginationTotalItem - pageStore.paginationLimit;
    const pagination = Array(8).fill(0);
    if (pageCount > 0) {
      pagination[1] = 1;
      let max = countMath;
      if (countMath > 5) {
        max = 5;
        pagination[7] = countMath;
      }

      if (pageNumber <= 4) {
        if (pageNumber != 1) {
          pagination[pageNumber - 1] = pageNumber - 1;
        }

        for (let number = 1; number <= max; number++) {
          pagination[number] = number;
        }
        if (pageNumber != 1) {
          pagination[pageNumber - 1] = pageNumber - 1;
        }
        pagination[pageNumber] = pageNumber;
      }
      if (pageNumber >= countMath - 3 && pageNumber > 4 && countMath > 4) {
        pagination[3] = countMath - 4;
        pagination[4] = countMath - 3;
        pagination[5] = countMath - 2;
        pagination[6] = countMath - 1;
      }
      if (pageNumber < countMath - 3 && pageNumber > 4 && countMath > 4) {
        pagination[3] = pageNumber - 1;
        pagination[4] = pageNumber;
        pagination[5] = pageNumber + 1;
      }

      for (let number = 1; number <= 7; number++) {
        if (pagination[number] != 0) {
          result.push(
            <li className={pagination[number] === pageNumber ? 'page-item active' : 'page-item'} key={number + Math.random()}>
              <a
                className="page-link"
                onClick={(e) => {
                  e.preventDefault();
                  pageStore.setSelectedPage(pagination[number]);
                }}
                href="!#"
              >
                {pagination[number]}
              </a>
            </li>,
          );
        }
        if (pagination[number] == 0 && countMath > 6) {
          result.push(
            <li className="page-item" key={number + Math.random()}>
              <span className="page-link">...</span>
            </li>,
          );
        }
      }
    }

    return (
      <>
        <div className=" pagination-mt">
          <ul className="pagination" key={Math.random()}>
            {result}
          </ul>
        </div>
      </>
    );
  }
  //----------------------
  useEffect(() => {
    pageStore.setPaginationDefault();
  }, []);
  useEffect(() => {
    pageNumberInput.current = String(pageStore.paginationSelected);
  }, [pageStore.paginationSelected, pageStore.paginationLimit, pageStore.paginationTotalItem]);
  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <PaginationSelectLimit />
        </div>

        <div className="col-md-10">
          <PaginationInputPageNumber />
          <PaginationComponent />
        </div>
      </div>
    </>
  );
});
