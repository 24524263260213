import { dataPagesMenu } from "app/data/default";
import { Keys } from "shared/types";
import { UIInfoLoader, UIdateUpdate } from "./info";
import { usePageContext } from "shared/context/page";

type TUITypographyH1 = {
    pageCode: string;
    addtoname?: string;
    loading?: boolean;
}
/* USE
const pageCode: string = 'users.list';
<UITypographyH1 pageCode={pageCode} loading={loading} />
*/
export function UITypographyH1({ pageCode, addtoname,loading }: TUITypographyH1) {
    const obj1: Keys = { key: pageCode };
    const dataPage = dataPagesMenu[obj1.key as keyof typeof dataPagesMenu];
    return (
        <>
            <div className="subheader">
                <h1 className="subheader-title">
                    <i className={'subheader-icon fal ' + dataPage.icon}></i> {dataPage.name} {addtoname && <>{addtoname}</>} 
                    {loading && <UIInfoLoader />} 
                    <small>
                        {dataPage.text} 
                    </small>
                </h1>
                <div className="float-right">
   
                </div>
            </div>
        </>
    )
}

//====================================
type TUIBreadcrumbs = {
    pageCode: string;
    loading?: boolean;
}
/* USE 
const [update, setUpdate] = useState(false); // флаг обновления страницы
<UIBreadcrumbs pageCode={pageCode} loading={loading} />
const { updatePage,setLoadingPage,setErrorPage,loadingPage, errorPage } = usePageContext();
*/
export function UIBreadcrumbs(props: TUIBreadcrumbs) {
    const {refreshPage}=usePageContext();
    const obj1: Keys = { key: props.pageCode };
    const dataPage = dataPagesMenu[obj1.key as keyof typeof dataPagesMenu];

    return (<>
        <ol className="breadcrumb page-breadcrumb">
            <li className="breadcrumb-item"><a href="/"> <i className="fal fa-home-alt"></i></a></li>
            {/*<li className="breadcrumb-item">Documentation</li>*/}
            <li className="breadcrumb-item active">{dataPage.name}</li>
            <li className="position-absolute pos-top pos-right d-none d-sm-block">
                <button className="btn btn-outline-primary btn-pills waves-effect waves-themed" onClick={refreshPage}>
                    
              
                    {props.loading && <><UIInfoLoader /> Загрузка</>}
                    {!props.loading && <><UIdateUpdate /></>}
                 
                </button>
                
            </li>
        </ol>
    </>);
}