import { Link, useParams } from 'react-router-dom';
import { IUserUpdate, IUserUpdateDefault, IWUsersUpdateProfile } from '../models';
import { useEffect, useState } from 'react';
import { useHttp } from 'hooks/http.hooks';
import { UIAlertError, UIAlertSuccess } from 'shared/ui/alert';
import { FormInput, FormPreventDefault, FormSelect } from 'shared/ui/form';
import { userRoles } from 'app/data/default';
import { LANG_default } from 'app/data/lang';
import { ApiUserUpdate } from '../api';

export function WUsersUpdateProfile({ userdata }: IWUsersUpdateProfile) {
  const language = 'ru';
  const [userdataold, setUserDataOld] = useState<IUserUpdate>(userdata);
  const params = useParams(); // из url : id
  //const { loading, request, error, success } = useHttp();
  const [form, setForm] = useState<IUserUpdate>(userdata);
  //const [flagСhanged, setFlagСhanged] = useState(false);
  const [msgsuccess, setMsgSuccess] = useState('');
  const [msgerror, setMsgError] = useState('');
  //------------ обновление данных формы
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  //-----
  const changeSelectHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  //----- Очистка формы
  const CleanForm = () => {
    setForm(userdata);
  };
  //========
  const { loading, response: apiUpdate, error, success } = ApiUserUpdate({ user_id: params.id ? params.id : '' }); //  API
  //============
  const saveHandler = async () => {
    try {
      console.log(form);
      if (form.login == '' && form.email == '' && form.role == '') {
        setMsgError('Заполните обязательные поля');
      }
      if (form.login != '' && form.email != '' && form.role != '') {
        if (userdataold.name != form.name) {
          try {
            const res = await apiUpdate({ name: form.name });
          } finally {
          }
        }
        if (userdataold.email != form.email) {
          try {
            const res = await apiUpdate({ email: form.email });
          } catch (e: any) {
          } finally {
          }
        }
        if (userdataold.role != form.role) {
          try {
            const res = await apiUpdate({ role: form.role });
          } finally {
          }
        }
        if (error) {
          setMsgError(error);
        } else {
          setMsgSuccess('Данные успешно сохранены');
          //setFlagСhanged(!flagСhanged);
        }
      }
      /*
            if (form.login != '') {

                if (userdataold.name != form.name) {
                    const data = await request('/api/user/' + params.id + '/update', 'PUT', { "name": form.name });
                }
                if (userdataold.email != form.email) {
                    const data = await request('/api/user/' + params.id + '/update', 'PUT', { "email": form.email });
                }
                if (userdataold.role != form.role) {
                    const data = await request('/api/user/' + params.id + '/update', 'PUT', { "role": form.role });
                }
                if (error) {
                    setMsgError(error);
                } else {
                    setMsgSuccess('Данные успешно сохранены');
                    setFlagСhanged(!flagСhanged);
                }

                //const data = await request('/api/user/' + params.id + '/update', 'PUT', json);
                // console.log('data', data);

            }
            */
    } catch (e) {
      console.log(e);
      setMsgError(LANG_default[language].error500);
    }
  };
  useEffect(() => {
    CleanForm();
    setUserDataOld(userdata);
  }, [userdata]);

  return (
    <>
      <div className="panel-hdr">
        <h2>
          Профиль{' '}
          <span className="fw-300">
            <i> {form.name}</i>{' '}
          </span>
        </h2>
      </div>
      {error && <UIAlertError text={error} />}
      {msgerror && <UIAlertError text={msgerror} />}

      {msgsuccess && <UIAlertSuccess text={msgsuccess} />}
      <div className="panel-container show">
        <div className="panel-content">
          <div className="row ">
            <div className="col-12 ">
              <form onSubmit={FormPreventDefault}>
                <table className="table table-striped m-0">
                  <tbody>
                    <tr>
                      <th>id</th>
                      <td>{params.id}</td>
                      <td> </td>
                    </tr>
                    <tr>
                      <th>Логин</th>
                      <td>
                        <b>{form.login}</b>
                      </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="name">
                          Имя <span className="text-danger">*</span>
                        </label>{' '}
                      </th>
                      <td>
                        <FormInput label="" name="name" fOnChange={changeHandler} value={form.name} required={true} helpingText="" />
                      </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="example-email-2">
                          Email <span className="text-danger">*</span>
                        </label>
                      </th>
                      <td>
                        <FormInput label="" name="email" fOnChange={changeHandler} value={form.email} required={true} helpingText="" />
                      </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="role">
                          Роль <span className="text-danger">*</span>
                        </label>
                      </th>
                      <td>
                        <FormSelect label="" name="role" fOnChange={changeSelectHandler} value={form.role} optionValues={userRoles} required={true} />
                      </td>
                      <td> </td>
                    </tr>
                  </tbody>
                </table>
                <hr className="mt-30" />
                <div className="mb-30">
                  <span className="text-danger">*</span> - обязательные поля
                </div>
                <div className="row">
                  <div className="col-6">
                    <button className="btn btn-default waves-effect waves-themed" onClick={CleanForm}>
                      Отменить
                    </button>
                  </div>
                  <div className="col-6 text-right">
                    <button type="submit" className="btn btn-primary waves-effect waves-themed" onClick={saveHandler} disabled={loading}>
                      Сохранить
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
