import { useHttp } from 'hooks/http.hooks';
import { useCallback, useState } from 'react';
import { devLog } from 'shared/lib/default';
import { DataHttp } from 'shared/types';

// GET /api/bypass_status     - состояние байпаса
export const ApiBypassGetStatus = () => {
  //----
  const url = '/api/bypass_status';
  const textSuccess = 'Ok bypass_status';
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');

  //-------
  const response: any = useCallback(async () => {
    try {
      const res: any = await request(url);
      //devLog(url, res.data.toString());
      if (res.data.result == 'error') {
        // devLog('res.data.result', res.data.result);
        throw new Error(res.data.error || 'Ошибка http запроса');
      }
      if (error) {
        devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      // devLog('success', textSuccess);
      return res;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);

  return { loading, response, error, success };
};

// GET /api/admin/bypass/vote_enable
export const ApiBypassGetVoteEnable = () => {
  //----
  const url = '/api/admin/bypass/vote_enable';
  const textSuccess = 'Ok vote_enable';
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');

  //-------
  const response = useCallback(async () => {
    try {
      const res: any = await request(url);
      //devLog(url, res.data.toString());
      if (error) {
        devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      //devLog('success', textSuccess);
      return res;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);
  return { loading, response, error, success };
};

// GET /api/admin/bypass/revoke_enable
export const ApiBypassGetRevokeEnable = () => {
  //----
  const url = '/api/admin/bypass/revoke_enable';
  const textSuccess = 'Ok revoke_enable';
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');

  //-------
  const response = useCallback(async () => {
    try {
      const res: DataHttp = await request(url);
      //devLog(url, res.data.toString());
      if (error) {
        devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      //devLog('success', textSuccess);
      return res;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);
  return { loading, response, error, success };
};

// GET /api/admin/bypass/vote_disable
export const ApiBypassGetVoteDisable = () => {
  //----
  const url = '/api/admin/bypass/vote_disable';
  const textSuccess = 'Ok vote_disable';
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');

  //-------
  const response = useCallback(async () => {
    try {
      const res: DataHttp = await request(url);
      //devLog(url, res.data.toString());
      if (error) {
        devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      //devLog('success', textSuccess);
      return res;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);
  return { loading, response, error, success };
};

// GET /api/admin/bypass/revoke_disable
export const ApiBypassGetRevokeDisable = () => {
  //----
  const url = '/api/admin/bypass/revoke_disable';
  const textSuccess = 'Ok revoke_disable';
  //----
  const { loading, request, error } = useHttp();
  const [success, setSuccess] = useState('');

  //-------
  const response = useCallback(async () => {
    try {
      const res: DataHttp = await request(url);
      // devLog(url, res.data.toString());
      if (error) {
        devLog('error', error);
        throw new Error(error || 'Ошибка http запроса');
      }
      setSuccess(textSuccess);
      // devLog('success', textSuccess);
      return res;
    } catch (e: any) {
      devLog(url, e.name + ' ' + e.message);
      throw e;
    }
  }, [request]);
  return { loading, response, error, success };
};
/*
Поменялось:

GET /api/status   - добавлено поле emerg_bypass_avail, которое отображается только если параметр true 

Добавлено:

GET /api/bypass_status     - состояние байпаса

pub struct BypassVote {
    pub user: String,
    pub weight: u8,
    pub dtime: bson::DateTime,
}

pub struct BypassState {
    doctype: String,
    state: bool,
    vote_for: u8,
    voted_enable: Vec<BypassVote>,
    voted_disable: Vec<BypassVote>,
    voting_expire: Option<bson::DateTime>,
}

GET /api/admin/bypass/vote_enable
GET /api/admin/bypass/revoke_enable
GET /api/admin/bypass/vote_disable
GET /api/admin/bypass/revoke_disable

{"result":"ok", "data": <BypassState>}
или
{"result":"error", "error":"..."}

*/
