import { IUserItem } from 'modules/users/models';
import { IdateT } from 'shared/models';

type ICLDAPItemAutoProvisionSchedule = {
  deactivate?: boolean;
  import?: boolean;
};
type ICLDAPItemConfig = {
  upn_as_login?: boolean;
  request_limit?: string | null | number;
  chpass_enabled?: boolean;
  chpass_helper_group_dn?: string;
  auto_provision_event?: boolean | null;
  auto_provision_schedule?: ICLDAPItemAutoProvisionSchedule | null;
  track_account_expiration?: boolean | null;
  track_password_expiration?: boolean | null;
};
export interface ICLDAPListItem {
  _id: string;
  name: string;
  server: string;
  base: string;
  filter: string;
  groups?: string;
}

export interface ICLDAPNew {
  name: string;
  server: string;
  base: string;
  filter: string;
  login: string;
  pass: string;
  groups?: [];
  config: ICLDAPItemConfig;
  requestlimit?: number;
}

//----- ICLDAPItem
export interface ICLDAPItem {
  _id: string;
  name: string;
  server: string;
  base: string;
  filter: string;
  groups?: [];
  login?: string;
  pass?: string;
  config: ICLDAPItemConfig | null;
  state?: string;
  last_update?: {};
  configlogin?: string;
  requestlimit?: string;
  autocheduleimport?: boolean;
  autocheduledeactivate?: boolean;
  chpassenabled?: boolean;
  chpasshelpergroupdn?: string;
}
export const ICLDAPItemDefault = {
  _id: '',
  name: '',
  server: '',
  base: '',
  filter: '',
  config: {
    upn_as_login: false,
    request_limit: '',
    auto_provision_schedule: null,
    chpass_enabled: false,
    chpass_helper_group_dn: '',
    track_account_expiration: false,
    track_password_expiration: false,
  },
  requestlimit: '',
  configlogin: '',
  autocheduleimport: false,
  autocheduledeactivate: false,
};

export interface ICLDAPUsers {
  name: string;
  lastname: string;
  login: string;
  upn: string;
  email: string;
  groups: [];
  connector?: {};
  linked?: boolean;
  disabled: boolean;
  local_active?: boolean;
  local_archived?: boolean;
  local_id?: string;
  sid?: string;
  pwd_last_set?: number | null;
  account_expires?: IdateT | null;
  password_expires?: IdateT | null;
}

export const ICLDAPUsersDefault: ICLDAPUsers = { name: '', lastname: '', login: '', upn: '', email: '', groups: [], disabled: false };

export type ICLDAPTableUsersList = {
  connector_id: string;
  upn_as_login: boolean;
  loading: boolean;
};
export type ICLDAPTableUsersList_old = {
  list: ICLDAPUsers[];
  connector_id: string;
  upn_as_login: boolean;
  loading: boolean;
};
export type TadfButtons = {
  item: any;
  index: any;
};
