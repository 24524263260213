import { IAuditItem } from 'entities/audit/models';
import { FAuditModalRightInfo } from 'features/audit/ui/ModalRightInfo';
import moment from 'moment';
import { useState } from 'react';

type IWAuditTableDaily = {
  list: IAuditItem[];
};
export function WAuditTableDaily({ list }: IWAuditTableDaily) {
  const [modalData, setModalData] = useState<IAuditItem>();
  const TableHeader = () => {
    return (
      <tr>
        <th>time</th>
        <th>ID</th>
        <th>OUID</th>
        <th>uname</th>
        <th>msg</th>
      </tr>
    );
  };
  return (
    <>
      {!list.length && <div className="text-center">За указанный период данных нет</div>}
      {list && list.length > 0 && (
        <table
          id="dt-basic-example"
          className="table table-bordered table-hover table-striped w-100"
        >
          <thead className="bg-primary-600">
            <TableHeader />
          </thead>
          <tbody>
            {list.map((item) => {
              return (
                <tr key={item._id}>
                  <td>
                    {/*item.time.slice(0, 19).replace('T', ' ')*/}
                    {moment(item.time).local().format('DD.MM.YYYY HH:mm:ss')}
                  </td>
                  <td>{item.ID}</td>
                  <td>{item.OUID}</td>

                  <td>{item.uname}</td>
                  <td>
                    <div className="d-flex">
                      <div className="p-2 w-100 ">{item.msg}</div>
                      <div className="p-2 flex-shrink-1  ">
                        <button
                          className="btn btn-outline-primary  waves-effect waves-themed"
                          data-toggle="modal"
                          data-target=".default-example-modal-right"
                          onClick={() => {
                            setModalData(item);
                          }}
                        >
                          <i className="fal fa-info"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <TableHeader />
          </tfoot>
        </table>
      )}
      {modalData && (
        <FAuditModalRightInfo modalData={modalData} dataTarget="default-example-modal-right" />
      )}
    </>
  );
}
