import { AuthContext } from 'entities/users/AuthContext';
import { FNotificationEmailNav } from 'features/NotificationEmail/NavTabs';
import { useContext } from 'react';
import { useAuthContext } from 'shared/context/auth';
import { UIAlertError } from 'shared/ui/alert';
import { UITypographyH1 } from 'shared/ui/typography';

export const PageNEmailServersUpdate = () => {
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const pageCode: string = 'settings.notification.email.servers.update';
  return (
    <>
      <UITypographyH1 pageCode={pageCode} />
      {/*error && <UIAlertError text={error} />*/}
      {(auth.userrole == 'superadmin' || auth.userrole == 'admin') && (
        <div>
          <div className="panel-container show">
            <div className="panel-content">
              <div className="card border">
                <FNotificationEmailNav />
                <div className="card-body">Изменить</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
