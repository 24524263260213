import { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { devLog } from "shared/lib/default";
import { useAuthContext } from "shared/context/auth";

export const useHttp = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState('');

    const { token, logout } = useAuthContext();

    const navigate = useNavigate();

    const request = useCallback(async (url, method = 'GET', body = {}, headersOn = {}) => {
        setError(null);
        setSuccess('');
        setLoading(true);


        let myhead = {
            "Accept": "application/json",
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            'Access-Control-Allow-Credentials': true
        };
        if (token) {
            let bearer = `Bearer ${token}`;
            myhead = {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=utf-8",
                "Access-Control-Allow-Headers": "Content-Type",
                'Access-Control-Allow-Credentials': true,
                "authorization": bearer
            };
        }

        try {

            body = JSON.stringify(body);
            if (body.length === 2 || method === 'GET') {
                body = null;
            }

            const responsehttp = await fetch(url, { method: method, body: body, headers: myhead, cache: "no-store" })
                .then((res) => {

                    const resstatus = res.status;

                    if (res.status === 401) {
                        logout(); // разлогинить
                        navigate('/'); // редирект
                        throw new Error('401');
                    }
                    //403	Forbidden	"Запрещено". У клиента нет прав доступа к содержимому, поэтому сервер отказывается дать надлежащий ответ.
                    if (res.status == 403) {
                        throw new Error('Нет прав');
                    }
                    const data = res.json();
                    data.status = res.status;

                    setLoading(false);
                    if (res.status == 500) {
                        // throw new Error(res.status + ' ' + res.statusText); // || 'Ошибка 500. Ошибка на стороне сервера.'
                    }
                    return data;
                });

            if (responsehttp.error) {
                throw new Error(responsehttp.error || 'Ошибка http запроса');
            }


            setLoading(false);


            return responsehttp;

        } catch (e) {
            setLoading(false);
            setError(e.message);

            devLog('ошибка', e);
            throw e;
        }

    }, [token, logout, navigate]);

    const clearError = useCallback(() => { setError(null); }, []);

    return { loading, request, error, clearError, success }

}
