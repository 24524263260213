import { NEmailTLSversion, NEmailTransportType } from "app/data/default";
import { ApiNEmailServersNew } from "entities/NotificationEmail/api";
import { INEmailServersNewDefault } from "entities/NotificationEmail/models";
import { AuthContext } from "entities/users/AuthContext";
import { FNotificationEmailNav } from "features/NotificationEmail/NavTabs"
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { usePageContext } from "shared/context/page";
import { devLog } from "shared/lib/default";
import { UIAlertError, UIAlertSuccess } from "shared/ui/alert";
import { FormButtonClean, FormCheckbox, FormGenerate, FormInput, FormPreventDefault, FormSelect } from "shared/ui/form";
import { UIInfoLoader, UIInfoNoAccess } from "shared/ui/info";
import { UITypographyH1 } from "shared/ui/typography";

export function FNEmailServerNew() {
    const { loading, response: apiNew, error, success } = ApiNEmailServersNew(); //  API
    const { updatePage, loadingPage, errorPage, setLoadingPage, setErrorPage } = usePageContext();

    const FormDefault = useRef(INEmailServersNewDefault);
    //---
    const [form, setForm] = useState(FormDefault.current);
    //----- Очистка формы
    const CleanForm = () => {
        setForm(FormDefault.current);
    }
    const saveInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    }
    const saveSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value });
        devLog(event.target.name, event.target.value);
    }
    const saveChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.checked });
        //console.log(event.target.checked);
    }
    //======

    const sendForm = async () => {
        //cleanMsg();
        try {
            devLog('form',JSON.stringify(form));
            if (form.name == ''
                || form.sender_email == ''
                || form.transportType == ''
            ) {
                setErrorPage('Заполните обязательные поля');
            } else {
                
                let smtp: any = new Object();
                smtp={
                    host: form.smtp_host,
                    port: form.smtp_port,
                    login: form.smtp_login,
                    passw: form.smtp_passw,
                    ignore_cert: form.smtp_ignore_sert
                }
                if(form.transportType != 'SmtpInsecure'){
                    smtp.ignore_cert=false;
                }
                if(form.transportType == 'SmtpStarttls' || form.transportType == 'SmtpTls'){
                    smtp.min_tls_version=form.smtp_tls_version;
                }
                const postal = {
                    api_url: form.postal_url,
                    api_key: form.postal_key
                }
                //let sender: any = new Object();
                let sender: any = '';
                sender = form.sender_email;
                if (form.sender_name != '') { sender= form.sender_name+' <'+sender+'>'; }
                //---
                let opts: any = new Object();
                if (form.transportType == 'Postal') { opts.postal = postal; }
                if (form.transportType != 'Postal') { opts.smtp = smtp; }
                let dataArray: any = new Object();
                dataArray.name = form.name;
                dataArray.transport_type = form.transportType;
                dataArray.sender = sender;
                dataArray.opts = opts;
                if (opts.length > 0) {  }
                const res = await apiNew(dataArray);
                if (error) { setErrorPage(error); }
            }
        } finally { }
    }
    //---
    return (<>
        {loading && <UIInfoLoader />}
        {errorPage && <UIAlertError text={errorPage} />}
        {success && <UIAlertSuccess text={success} />}
        <form onSubmit={FormPreventDefault}>
            <FormInput label="Название" name="name" fOnChange={saveInput} value={form.name} required={true} helpingText="Название сервера" />
            <hr />
            <h3 className="mb-3">Отправитель</h3>
            <FormInput label="Имя отправителя" name="sender_name" fOnChange={saveInput} value={form.sender_name} helpingText="" />
            <FormInput label="Email отправителя" name="sender_email" fOnChange={saveInput} value={form.sender_email} required={true} helpingText="" />
            <hr />
            <h4 className="mb-3">Cетевой протокол</h4>

            <FormSelect label="Тип отправки" name="transportType" fOnChange={saveSelect} value={form.transportType} optionValues={NEmailTransportType} required={true} />
            {(form.transportType == 'SmtpStarttls' || form.transportType == 'SmtpTls' || form.transportType == 'SmtpInsecure') &&
                <>
                    <FormInput label="host" name="smtp_host" fOnChange={saveInput} value={form.smtp_host} required={true} helpingText="" />
                    <FormInput label="Порт" name="smtp_port" fOnChange={saveInput} value={form.smtp_port} required={true} helpingText="" />
                    <FormInput label="Логин" name="smtp_login" fOnChange={saveInput} value={form.smtp_login} required={true} helpingText="" />
                    <FormInput label="Пароль" name="smtp_passw" fOnChange={saveInput} value={form.smtp_passw} required={true} helpingText="" />
                    {form.transportType != 'SmtpInsecure' &&
                        <>
                            <FormCheckbox label="Игнорировать сертификат сервера (ignore_cert)" name="smtp_ignore_sert" fOnChange={saveChecked} value={form.smtp_ignore_sert} required={true} helpingText="" />
                            <FormSelect label="Версия TLS" name="smtp_tls_version" fOnChange={saveSelect} value={form.smtp_tls_version} optionValues={NEmailTLSversion} required={true} />
                        </>
                    }
                </>
            }
            {form.transportType == 'Postal' &&
                <>
                    <FormInput label="url" name="postal_url" fOnChange={saveInput} value={form.postal_url} required={true} helpingText="" />
                    <FormInput label="key" name="postal_key" fOnChange={saveInput} value={form.postal_key} required={true} helpingText="" />

                </>
            }
            <hr className="mt-30" />
            <div className="mb-30"><span className="text-danger">*</span> - обязательные поля</div>
            <div className="row">
                <div className="col-6">
                    <FormButtonClean fOnChange={CleanForm} key='button' />
                </div>
                <div className="col-6 text-right">
                    <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-themed"
                        onClick={sendForm}
                        disabled={loading}
                    >Сохранить</button>
                </div>
            </div>
        </form>
    </>);
}