import { useCallback, useEffect, useState } from 'react';
import { ApiBypassGetStatus } from '../api';
import { devLog } from 'shared/lib/default';
import { UIAlertError } from 'shared/ui/alert';
import { BypassStatusDefault, IBypassStatus, IBypassUser } from '../models';
import { WBypassVoteDisable } from './WBypassVoteDisable';
import { FVotedUsers } from '../features/FVotedUsers';
import { WBypassVoteEnable } from './WBypassVoteEnable';
import { usePageContext } from 'shared/context/page';
import { WBypassRevokeEnable } from './WBypassRevokeEnable';
import { WBypassRevokeDisable } from './WBypassRevokeDisable';
import { useAuthContext } from 'shared/context/auth';

export const WBypassStatus = () => {
  //=============
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const { globalBypassavail, globalBypass, setPageBypass, updatePage } = usePageContext();
  const [bypass, setbypass] = useState<IBypassStatus>(BypassStatusDefault);
  const [selfVoteEnable, setSelfVoteEnable] = useState(false);
  const [selfVoteDisable, setSelfVoteDisable] = useState(false);

  //auth.userlogin

  /*
  const {
    loading: loadingSt,
    response: responseSt,
    error: errorSt,
    success: successSt,
  } = ApiUserAuthCheck();
  
  const dataSt = useCallback(async () => {
    try {
      const resSt = await responseSt();
      console.log('!!!!!!!!', JSON.stringify(resSt.data));
      setbypassavail(resSt.data.emerg_bypass_avail);
      SLocalstoreUpdate({ bypassavail: resSt.data.emerg_bypass_avail });
    } catch (e) {
      if (e instanceof Error) {
        devLog('WBypassStatus', e.message);
      }
    }
  }, [responseSt]);
  */
  //====================== off
  const { loading, response, error, success } = ApiBypassGetStatus();
  const dataList = useCallback(async () => {
    try {
      const res = await response();
      const newbypass = res.data != null ? res.data.state : false;
      //console.log('newbypass', newbypass);
      //-----
      if (globalBypass != newbypass) {
        setPageBypass(newbypass);
      }
      //-----
      if (res.data == null) {
        setbypass(BypassStatusDefault);
      } else {
        setbypass(res);
      }
      /*
      const localbypass = SLocalstoreGetBypass();
      if (localbypass) {
        //setbypassavail(localbypass.bypassavail);
        if (localbypass.bypass !== bypass.data.state) {
          //console.log('newbypass save', newbypass);
          SLocalstoreUpdate({ bypass: newbypass });
        }
      }
      */
      //=================================

      //===================================================== off
      //---
      devLog('data WBypassStatus', JSON.stringify(res));
      setSelfVoteEnable(false);
      setSelfVoteDisable(false);
      //----
      res.data.voted_enable.map((item: IBypassUser) => {
        let user = item.user.split('::');
        if (auth.userlogin == user[1]) {
          setSelfVoteEnable(true);
        }
      });
      res.data.voted_disable.map((item: IBypassUser) => {
        let user = item.user.split('::');
        if (auth.userlogin == user[1]) {
          setSelfVoteDisable(true);
        }
      });
    } catch (e) {
      if (e instanceof Error) {
        devLog('WBypassStatus', e.message);
      }
    }
  }, [response]);
  //=============
  useEffect(() => {
    /*
    const timerId = setInterval(() => {
      //dataSt();
      //dataList();
    }, 3000);
    */
  }, []);
  useEffect(() => {
    dataList();
  }, [updatePage, globalBypass, globalBypassavail]);
  //=============

  return (
    <>
      {error && <UIAlertError text={error} />}
      {/*success && <UIAlertSuccess text={success} />*/}
      {!globalBypassavail && (
        <>
          <div className="panel-tag">
            <b>Функцилнал отключен</b>
            <br />
            Возможность настройки "Emergency bypass" включается в настройках системы ПО.
            <br />
            Информация и доступе к настройкам "Emergency bypass" обновляется каждые 5 секунд.
          </div>
        </>
      )}
      {globalBypassavail && (
        <div className="row">
          <div className="col-xl-6">
            <div className="panel">
              <div className="panel-hdr">
                <h2>
                  Emergency bypass
                  <span className="fw-300">
                    <i>Статус</i>
                  </span>
                </h2>

                <div className="panel-toolbar ml-2">
                  <h5 className="m-0">
                    {bypass.data && (
                      <span
                        className={
                          bypass.data.state
                            ? 'badge badge-success fw-400 l-h-n'
                            : 'badge badge-secondary  fw-400 l-h-n'
                        }
                      >
                        {bypass.data.state ? 'Включен' : 'Отключен'}
                      </span>
                    )}
                  </h5>
                </div>
              </div>
              <div className="panel-container show">
                <div className="panel-content">
                  <div className="panel-tag">
                    Для отключения может потребоваться несколько пользователей. Вес голоса зависит
                    от роли.
                  </div>
                  {bypass.data && (
                    <div className=" ">
                      {/* включен */}
                      {bypass.data.state && (
                        <>
                          {bypass.data.voted_disable.length > 0 && (
                            <FVotedUsers users={bypass.data.voted_disable} />
                          )}
                        </>
                      )}
                      {/* отключен */}
                      {!bypass.data.state && (
                        <>
                          {bypass.data.voted_enable.length > 0 && (
                            <FVotedUsers users={bypass.data.voted_enable} />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {bypass.data && (
                  <div className="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted  ">
                    {/* отключен*/}
                    {!bypass.data.state && (
                      <>
                        {!selfVoteEnable && <WBypassVoteEnable />}
                        {selfVoteEnable && <WBypassRevokeEnable />}
                      </>
                    )}
                    {/* включен */}
                    {bypass.data.state && !selfVoteDisable && <WBypassVoteDisable />}
                    {bypass.data.state && selfVoteDisable && <WBypassRevokeDisable />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
