import { useCallback, useState } from 'react';
import { useAxiosAuth } from 'shared/lib/axios';

export function ApiAuditPostListHour() {
  //---
  const url: string = '/api/audit/hourly';
  //const textSuccess = `Список постов успешно получен`;
  //---
  const [success, setSuccess] = useState('');
  const { loading, request, error } = useAxiosAuth();
  //---
  const response = useCallback(
    async (data: any) => {
      try {
        const res: any = await request({ url, method: 'POST', bodyJson: data });
        //setSuccess(textSuccess);
        return res;
      } finally {
      }
    },
    [request],
  );
  return { loading, response, error, success };
}

/*

/api/audit/range

pub struct RangeLogsRequest {
    pub searchdate: i64,
    pub enddate: Option<i64>,
    pub page: Option<u8>,
    pub limit: Option<u16>,
    pub uname: Option<String>,
    pub id: Option<u16>,
    pub fuzzy_query: Option<String>,
}
*/
export function ApiAuditPostListDaily() {
  //---
  //const url: string = '/api/audit/range';
  const url: string = '/api/audit/daily';
  const textSuccess = `Список постов успешно получен`;
  //---
  const [success, setSuccess] = useState('');
  const { loading, request, error } = useAxiosAuth();
  //---
  const response = useCallback(
    async (data: any) => {
      try {
        const res: any = await request({ url, method: 'POST', bodyJson: data });
        //setSuccess(textSuccess);
        return res;
      } finally {
      }
    },
    [request],
  );
  return { loading, response, error, success };
}

export function ApiAuditListPeriod() {
  //---
  const url: string = '/api/audit/range';
  //const textSuccess = `Список постов успешно получен`;
  //---
  const [success, setSuccess] = useState('');
  const { loading, request, error } = useAxiosAuth();
  //---
  const response = useCallback(
    async (data: any) => {
      try {
        const res: any = await request({ url, method: 'POST', bodyJson: data });
        //setSuccess(textSuccess);
        return res;
      } finally {
      }
    },
    [request],
  );
  return { loading, response, error, success };
}
