import { IdateT } from 'shared/models';

export interface IUsersNew {
  login: string;
  name?: string;
  email?: string;
  role?: string;
  verify_pass: string;
  password?: string;
  connector?: string;
  link?: string;
  groups?: [];
  sid?: string;
}

export interface IUserItem {
  _id?: string;
  login: string;
  name?: string;
  email?: string;
  role?: string;
  active?: boolean;
  archive?: boolean;
  date_created?: {};
  verify_pass?: string;
  methods?: {};
  password?: string;
  connector?: string;
  groups?: string[];
  sid?: string;
  expiry?: IdateT | null;
  password_expiry?: IdateT | null;
}
export const IUserItemDefault = { login: '' };
