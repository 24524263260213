import { ApiUserUpdate } from 'entities/users/api';
import { IUserItem } from 'entities/users/models';
import { useCallback, useMemo, useState } from 'react';
import { devLog } from 'shared/lib/default';
import { UIAlertError, UIAlertErrorSM, UIAlertSuccessSM } from 'shared/ui/alert';
type ICLDAPuserLinkUpdate = {
  id_random: number;
  user_id: string;
  login: string;
  connector_id: string;
  groups: string[];
};

export function FCLDAPuserLinkUpdate({ user_id, login, connector_id, groups, id_random }: ICLDAPuserLinkUpdate) {
  const [showButton, setShowButton] = useState(true);
  //---
  /*
    const DataUpdate:IUserItem={
        "login":login,
        "connector":connector_id,
        "methods":"External",
        "groups":groups
    }
    */
  const DataUpdate = useMemo(() => {
    let dArray: IUserItem = {
      login: login,
      connector: connector_id,
      methods: 'External',
      groups: groups,
    };
    return dArray;
  }, []);
  //---
  const { loading, UserUpdate, error, success } = ApiUserUpdate(user_id, DataUpdate);
  //---
  const Button = useCallback(async () => {
    try {
      const res = await UserUpdate();
      setShowButton(false);
      devLog('FCLDAPuserLinkUpdate success ', success);
    } catch (e) {
      if (e instanceof Error) {
        devLog('catch e ', e.message);
      }
    }
  }, [UserUpdate]);
  return (
    <>
      {error && <UIAlertErrorSM text={error} />}
      {success && (
        <>
          <UIAlertSuccessSM text={'Привязан ' + login} />
        </>
      )}
      {showButton && (
        <button className="btn btn-warning btn-block waves-effect waves-themed" onClick={Button} disabled={loading}>
          Привязать
        </button>
      )}
    </>
  );
}
