interface IAlertProps {
  title?: string;
  text: string;
}

export function UIAlertText({ title, text }: IAlertProps) {
  return (
    <>
      <div className="alert alert-primary">
        <div className="d-flex flex-start w-100">
          <div className="mr-2 hidden-md-down">
            <span className="icon-stack icon-stack-lg">
              <i className="base-2 icon-stack-3x color-primary-400"></i>
              <i className="base-3 icon-stack-2x color-primary-600 opacity-70"></i>
              <i className="fal fa-info icon-stack-1x text-white opacity-90"></i>
            </span>
          </div>
          <div className="d-flex flex-fill">
            <div className="flex-fill">
              <span className="h5">{title}</span>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function UIAlertSuccess({ text }: IAlertProps) {
  return (
    <>
      <div className="panel-content p-0 mb-g">
        <div className="alert alert-success alert-dismissible fade show border-faded rounded-0 m-0" role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">
              <i className="fal fa-times"></i>
            </span>
          </button>
          {text}
        </div>
      </div>
    </>
  );
}
// small
export function UIAlertSuccessSM({ text }: IAlertProps) {
  return (
    <>
      <div className=" ">
        <div className="badge border border-success text-success" role="alert">
          {text}
        </div>
      </div>
    </>
  );
}

export function UIAlertError({ text }: IAlertProps) {
  return (
    <>
      <div className="panel-content p-0 mb-g">
        <div className="alert alert-danger alert-dismissible fade show border-faded rounded-0 m-0" role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">
              <i className="fal fa-times"></i>
            </span>
          </button>
          {text}
        </div>
      </div>
    </>
  );
}
// small
export function UIAlertErrorSM({ text }: IAlertProps) {
  return (
    <>
      <div className="  ">
        <div className=" badge border border-danger text-danger" role="alert">
          {text}
        </div>
      </div>
    </>
  );
}
