import React, { useCallback, useEffect, useState } from 'react';
import { Pagination } from '../../shared/ui/Pagination';
import { useHttp } from '../../hooks/http.hooks';
import { dataPageLimit } from '../../app/data/default';
import { UIInfoNoAccess } from 'shared/ui/info';
import { IAuditItem } from 'entities/audit/models';
import { IPagination } from 'shared/models';
import { AuditDailyProvider } from './Context';
import { WAuditTableDaily } from 'widgets/audit/TableDaily';
import moment from 'moment';
//---react-datepicker
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { UIAlertError } from 'shared/ui/alert';
import { usePageContext } from 'shared/context/page';
import { ApiAuditPostListDaily } from 'entities/audit/api';
import { useAuthContext } from 'shared/context/auth';
import { usePagination } from '../../shared/ui/Pagination';
import { FormPreventDefault } from 'shared/ui/form';
registerLocale('ru', ru);

export function AuditListPage() {
  const pageCode: string = 'audit.daily';
  const { updatePage, setLoadingPage, setErrorPage, loadingPage, errorPage } = usePageContext();

  //const auth = useContext(AuthContext);
  const auth = useAuthContext();

  const { loading, request, error, success } = useHttp();

  const [auditlist, setAuditlist] = useState<IAuditItem[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    page: 1,
    total: 0,
    pcount: 0,
  });
  const [filter, setFilter] = useState({ searchdate: '', uname: '' });
  const [uname, setUname] = useState('');

  const [page, setPage] = useState<number>(1);
  const [pagetotal, setPageTotal] = useState<number>(0);
  const [pagecount, setPageCount] = useState<number>(0);
  const [modalData, setModalData] = useState<IAuditItem>();
  // ========================
  const { pageNumber, changePageTotal, changePageCount, pageLimit, PaginationComponent, PaginationSelectLimit, setPageNumber, PaginationInputPageNumber } = usePagination();
  // =====

  const [date1, setDate1] = useState(moment().local().subtract(1, 'hour').toDate());

  const changeUname = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('changeUname =' + event.target.value);
    console.log('changeUname =' + event.target.name);
    console.log(typeof event.target.value);
    setUname(event.target.value);
    setPage(1);
  };
  /*
  const changeSelectLimit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let numberlimit = Number(event.target.value);
    setLimit(numberlimit);
    setPage(1);
  };

  const paginate = useCallback(
    (PageNumber: number) => {
      console.log('PageNumber', PageNumber);
      let PageNumberN = Number(PageNumber);
      //setPagination({ ...pagination, 'page': PageNumberN });
      setPage(PageNumberN);
      console.log('page', page);
      //getList();
      //SearchHandler();
    },
    [page],
  );
*/
  const SearchHandler = () => {
    getList();
  };
  const { loading: loadingApiList, response: apiList, error: errorApiList } = ApiAuditPostListDaily(); //  API
  const getList = useCallback(async () => {
    try {
      const searchdate = moment(date1).unix() * 1000; //
      // dataReq - массив данных фильтра для запроса
      let dataReq: any = { page: page, limit: pageLimit, searchdate: searchdate, uname: uname.trim() };

      //==========
      const res = await apiList(dataReq); // POST LIST
      if (res.data) {
        setAuditlist(res.data);
        setPageTotal(res.total);
        setPageCount(res.count);
      } else {
        setPageTotal(0);
        setPageCount(0);
      }
      // new pagination on -------------
      if (res.data) {
        changePageTotal(res.total);
        changePageCount(res.count);
      } else {
        changePageTotal(0);
        changePageCount(0);
      }
      // new pagination off-------------
    } finally {
    }
  }, [apiList, date1, uname, page, pageLimit, changePageCount, changePageTotal]);

  //============= отслеживание
  useEffect(() => {
    setLoadingPage(loadingApiList);
  }, [loadingApiList, setLoadingPage]);
  useEffect(() => {
    setErrorPage(errorApiList);
  }, [errorApiList, setErrorPage]);
  //--- page,
  useEffect(() => {
    getList();
  }, [updatePage, page, pagecount]);
  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber]);
  //
  //let dateFormat = new Intl.DateTimeFormat('ru-RU', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(filter.searchdate);
  //if (auth.userrole != "superadmin" && auth.userrole != "admin" && auth.userrole != "auditor") {return <UIInfoNoAccess />}
  if (auth.accessRights(pageCode) !== true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loadingPage} />
      <UITypographyH1 pageCode={pageCode} loading={loadingPage} />
      {errorPage && <UIAlertError text={errorPage} />}
      <AuditDailyProvider value={modalData}></AuditDailyProvider>

      <div className="row">
        <div className="col-xl-12">
          <div id="panel-1" className="panel">
            <div className="panel-container show">
              <div className="panel-content">
                <div className="panel-tag">
                  <form onSubmit={FormPreventDefault}>
                    <div className="row">
                      <div className="col-xl-2">
                        <div className="form-group">
                          <label className="form-label" htmlFor="date1">
                            Дата
                          </label>
                          <DatePicker id="date1" locale="ru" className="form-control" selected={date1} onChange={(val: any) => setDate1(val)} dateFormat="dd.MM.yyyy" />
                        </div>
                      </div>
                      <div className="col-xl-2  ">
                        <div className="form-group">
                          <label className="form-label" htmlFor="uname">
                            Uname
                          </label>
                          <input type="text" className="form-control" id="uname" name="uname" defaultValue={uname} onChange={changeUname} />
                        </div>
                      </div>

                      <div className="col-xl-3  ">
                        <br />
                        <button type="submit" className="btn btn-primary waves-effect waves-themed " onClick={SearchHandler} disabled={loading}>
                          Применить
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-2">
                    <PaginationSelectLimit />
                  </div>

                  <div className="col-md-10">
                    <PaginationInputPageNumber />
                    <PaginationComponent />
                  </div>
                </div>

                <WAuditTableDaily list={auditlist} />

                <PaginationComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
