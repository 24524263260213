import { useEffect, useRef, useState } from 'react';
import { usePageContext } from 'shared/context/page';
import { devLog } from 'shared/lib/default';
import { Keys } from 'shared/types';
import { UIAlertError, UIAlertSuccess } from './alert';
interface IFormSelectOptionsProps {
  value: string;
  text: string;
}
interface IFormInputProps {
  label?: string;
  name: string;
  fOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  value?: string;
  required?: boolean;
  helpingText?: string;
}
interface IFormSelectProps {
  label?: string;
  name: string;
  fOnChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  type?: string;
  value?: string;
  required?: boolean;
  helpingText?: string;
  optionValues: any[];
}
// USE
//

//------------ Отмена перезагрузки формы
export const FormPreventDefault = (event: React.FormEvent) => {
  event.preventDefault();
};

/*USE
<FormInput label="server" name="server" fOnChange={changeHandler} required={true} helpingText="ldaps://10.1.1.2:636"  required={true} />
 */
// <FormInput label="name" name="name" fOnChange={changeHandler} value={form.name} required={true} helpingText="Название коннектора должно быть уникальным" />
export function FormInput({ label = '', name, fOnChange, type = 'text', required = false, helpingText = '', value = '' }: IFormInputProps) {
  const valueOld = useRef(value);
  return (
    <div className="form-group">
      {label && (
        <label className="form-label" htmlFor={name}>
          <b>{label}</b> {required && <span className="text-danger">*</span>} :
        </label>
      )}
      <div className="input-group bg-white shadow-inset-2">
        <input type={type} className={valueOld.current !== value ? 'form-control border-input-change' : 'form-control'} id={name} name={name} value={value} onChange={fOnChange} required={required} />
      </div>
      {helpingText && <span className="help-block">{helpingText}</span>}
    </div>
  );
}

/*USE
<FormSelect label="Соответствие поля login при импорте" name="config" fOnChange={changeSelectHandler} value='login' optionValues={[{value:'login',text:'login'},{value:'upn',text:'upn'}]} required={true} />
*/
export function FormSelect({ label, name, fOnChange, required = false, helpingText = '', value = '', optionValues }: IFormSelectProps) {
  const valueOld = useRef(value);
  return (
    <div className="form-group">
      {label && (
        <label className="form-label" htmlFor={name}>
          <b>{label}</b> {required && <span className="text-danger">*</span>} :
        </label>
      )}
      <select className={valueOld.current !== value ? 'form-control border-input-change' : 'form-control'} id={name} name={name} required={required} onChange={fOnChange} value={value}>
        {optionValues.map((item, index) => (
          <option key={index} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
      {helpingText && <span className="help-block">{helpingText}</span>}
    </div>
  );
}
interface IFormCheckbox {
  label?: string;
  name: string;
  fOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  value?: boolean;
  required?: boolean;
  helpingText?: string;
  typeswitch?: boolean;
}
export function FormCheckbox({ label, name, fOnChange, required = false, helpingText = '', value = false, typeswitch = false }: IFormCheckbox) {
  return (
    <div className="form-group">
      <div className={typeswitch ? 'custom-control custom-switch' : 'custom-control custom-checkbox'}>
        <input type="checkbox" id={name} name={name} className="custom-control-input" onChange={fOnChange} required={required} checked={value} />

        <label className="custom-control-label" htmlFor={name}>
          {label}
        </label>

        {helpingText && <span className="help-block">{helpingText}</span>}
      </div>
    </div>
  );
}

//==================================================================

//===================================================================
type FormButtonClean = {
  fOnChange: () => void;
};
export function FormButtonClean({ fOnChange }: FormButtonClean) {
  return (
    <button type="button" className="btn btn-light waves-effect waves-themed mr-3" onClick={fOnChange}>
      Очистить
      <span className="fal fa-brush ml-1" data-fa-transform="rotate-180"></span>
    </button>
  );
}

type TformJson = {
  form: string;
  type?: string;
  formkey?: Keys;
  label?: string;
  name: string;
  value?: string;
  required?: boolean;
  helpingText?: string;
  optionValues?: any[] | undefined;
};
type TAlertForm = {
  loading: boolean;
  error: string | null;
  success: string;
};
type TFormGenerate = {
  data: TformJson[];
  fonClickSave: (val: {}) => void;
  alert: TAlertForm;
};
export function FormGenerate({ data, fonClickSave, alert = { loading: false, error: '', success: '' } }: TFormGenerate) {
  const { updatePage } = usePageContext();
  //---
  const [loadingForm, setLoadingForm] = useState(alert.loading);
  const [errorForm, setErrorForm] = useState(alert.error);
  const [successForm, setSuccessForm] = useState(alert.success);
  //----
  const optionVDefault = [{ value: '-', text: 'нет' }];
  const result: any = []; // from returm
  const FormData = useRef(data);

  const FormDefaultGet = () => {
    let arr: any = {};
    FormData.current.map((item: TformJson) => {
      arr[item.name] = item.value;
    });
    return arr;
  };
  const FormDefault = useRef(FormDefaultGet);
  //---
  const [form, setForm] = useState(FormDefault.current);
  //---
  const saveInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const checkingRequiredFields = () => {
    let err = 0;
    FormData.current.map((item: TformJson) => {
      devLog('item.name', item.name); //[item.name] = item.value;
      devLog('form[item.name]', form[item.name]); //[item.name] = item.value;
      if (item.required) {
        if (form[item.name] == '') {
          err++;
        }
      }
    });
    if (err < 1) {
      devLog('fonClickSave', 'ok'); //[item.name] = item.value;
      fonClickSave(form);
    } else {
      setErrorForm('Заполните обязательные поля');
    }
  };
  const saveSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    devLog(event.target.name, event.target.value);
  };
  const CleanForm = () => {
    setForm(FormDefault.current);
    setErrorForm('');
    setSuccessForm('');
  };
  const CleanAlert = () => {
    if (errorForm !== '' || successForm !== '') {
      setErrorForm('');
      setSuccessForm('');
    }
  };
  //=======
  useEffect(() => {
    devLog('data', JSON.stringify(data));
    FormDefaultGet();
    setForm(FormDefault.current);
    devLog('FormDefault', JSON.stringify(FormDefault));
  }, []);
  /*
    useEffect(() => {
        CleanAlert();
    }, [form]);
    */

  useEffect(() => {
    devLog('form', JSON.stringify(form));
  }, [form]);
  useEffect(() => {
    setForm(FormDefault.current);
  }, [updatePage]);
  //loading, error, success
  useEffect(() => {
    setLoadingForm(alert.loading);
  }, [alert.loading]);
  useEffect(() => {
    setErrorForm(alert.error);
  }, [alert.error]);
  useEffect(() => {
    setSuccessForm(alert.success);
    setForm(FormDefault.current);
  }, [alert.success]);
  //---
  return (
    <>
      {errorForm && <UIAlertError text={errorForm} />}
      {successForm && <UIAlertSuccess text={successForm} />}
      <form onSubmit={FormPreventDefault} className="mt-3">
        {FormData.current.map((item: TformJson) => {
          if (item.form == 'input') {
            return <FormInput key={item.name} label={item.label} name={item.name} type={item.type} required={item.required} value={form[item.name]} helpingText={item.helpingText} fOnChange={saveInput} />;
          }
          if (item.form == 'select') {
            return <FormSelect key={item.name} label={item.label} name={item.name} required={item.required} value={form[item.name]} helpingText={item.helpingText} fOnChange={saveSelect} optionValues={item.optionValues ? item.optionValues : optionVDefault} />;
          }
        })}
        <hr className="mt-30" />
        <div className="mb-30">
          <span className="text-danger">*</span> - обязательные поля
        </div>
        <div className="row">
          <div className="col-6">
            <FormButtonClean fOnChange={CleanForm} key="button" />
          </div>
          <div className="col-6 text-right">
            <button type="submit" className="btn btn-primary waves-effect waves-themed" onClick={checkingRequiredFields} disabled={loadingForm}>
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
