import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { dataDefault } from '../../app/data/default';
import { useHttp } from '../../hooks/http.hooks';
import { useMessage } from '../../hooks/message.hook';
import { UIInfoLoader } from 'shared/ui/info';
import { UIAlertError, UIAlertSuccess } from 'shared/ui/alert';
import { useAuthContext } from 'shared/context/auth';

export function AuthPage() {
  //const auth = useContext(AuthContext);
  const { login, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const message = useMessage();

  const { loading, error, request, clearError, success } = useHttp(); // error
  const [form, setForm] = useState({ ulogin: '', password: '' });

  useEffect(() => {
    // js
    message(error);
    // clearError();
  }, [error, message, clearError, isAuthenticated, request]);

  //=========================
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
  };

  // обновление данных формы
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    //console.log(form);
  };
  const loginHandler = async () => {
    try {
      const data = await request('/login', 'POST', { login: form.ulogin, password: form.password });
      message(data.message);
      login(data.token, data.userId, data.login, data.role, data.backend_version);

      //console.log('data', data);
      navigate('/'); // редирект
    } catch (e) {
      // console.log(e);
    }
  };

  //-------------
  return (
    <>
      <div className="page-wrapper auth">
        <div className="page-inner bg-brand-gradient">
          <div className="page-content-wrapper bg-transparent m-0">
            <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
              <div className="d-flex align-items-center container p-0">
                <div className="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9 border-0">
                  <a href="#!" className="page-logo-link press-scale-down d-flex align-items-center">
                    <img src={dataDefault.logoimg} alt={dataDefault.project} aria-roledescription="logo" />
                    <span className="page-logo-text mr-1">{dataDefault.project}</span>
                  </a>
                </div>
                {/*  <a href="page_register.html" className="btn-link text-white ml-auto">
                                Create Account
                            </a>*/}
              </div>
            </div>
            <div
              className="flex-1"
              style={{
                background: 'url(assets/img/svg/pattern-1.svg) no-repeat center bottom fixed',
                backgroundSize: 'cover',
              }}
            >
              <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                <div className="row">
                  <div className="col col-md-6 col-lg-7 hidden-sm-down">
                    <h2 className="fs-xxl fw-500 mt-4 text-white">
                      ADF administration interface
                      <small className="h3 fw-300 mt-3 mb-5 text-white opacity-60">
                        Only authorized users may access administration interface. If you require any assistance, please contact tech support of your organization. If you need support from developers, contact tech support by the link below:
                      </small>
                    </h2>
                    <a href="https://support.powersecurity.org/" className="fs-lg fw-500 text-white opacity-70" target={'_blank'}>
                      PS support &gt;&gt;
                    </a>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto">
                    <h1 className="text-white fw-300 mb-3 d-sm-block d-md-none">Вход</h1>
                    <div className="card p-4 rounded-plus bg-faded">
                      <form onSubmit={submitHandler} id="authform">
                        <div className="form-group">
                          <label className="form-label" htmlFor="username">
                            Логин
                          </label>
                          <input type="text" id="username" className="form-control form-control-lg" name="ulogin" onChange={changeHandler} />
                          {/*<div className="invalid-feedback">No, you missed this one.</div>
                                                <div className="help-block">Your unique username to app</div>*/}
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="password">
                            Пароль
                          </label>
                          <input type="password" id="password" className="form-control form-control-lg" name="password" onChange={changeHandler} />
                          {/*<div className="invalid-feedback">Sorry, you missed this one.</div>
                                                <div className="help-block">Your password</div>*/}
                        </div>
                        <div className="form-group msg">
                          {error && <UIAlertError text={error} />}
                          {success && <UIAlertSuccess text={'Успешно'} />}
                        </div>
                        <div className="row no-gutters">
                          <div className="col-lg-6 pr-lg-1 my-2 "></div>
                          <div className="col-lg-6 pl-lg-1 my-2">
                            <button id="js-login-btn" type="submit" className="btn btn-info btn-block btn-lg waves-effect waves-themed" onClick={loginHandler} disabled={loading}>
                              Вход {loading && <UIInfoLoader />}{' '}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                  © 2023 - {new Date().getFullYear()}&nbsp;
                  <a href={dataDefault.copyrightlink} className="text-white opacity-40 fw-500" title={dataDefault.copyright} target="_blank" rel="noreferrer">
                    {dataDefault.copyright}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
