export type Email = string;
export type UniqueId = string;
export type DateTimeString = string;
export type DataHttp = {
  data: [];
  count: number;
  total: number;
  result?: string;
  error?: string;
};

export type Keys = {
  key: string;
};

export function noop() {} // пустая функция - тип
export function noopVal(val: any) {}

//----- объявление дефолтных значений
