import { useCallback, useContext, useEffect, useState } from 'react';
import { useHttp } from '../hooks/http.hooks';
import { AuthContext } from 'entities/users/AuthContext';
import { Link } from 'react-router-dom';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { devLog } from 'shared/lib/default';
import { usePageContext } from 'shared/context/page';
import { UIAlertError } from 'shared/ui/alert';
import { UIInfoNoAccess } from 'shared/ui/info';
import { useAuthContext } from 'shared/context/auth';
//==============================================
export const DashboardPage = () => {
  //============= данные о странице
  const pageCode: string = 'dashboard';
  //const auth = useContext(AuthContext);
  const { accessRights } = useAuthContext();

  const [update, setUpdate] = useState(false); // флаг обновления страницы
  const { updatePage, loadingPage, errorPage, setLoadingPage, setErrorPage } = usePageContext();
  //-------
  const { loading, request, error } = useHttp();
  const [datastatus, setDataStatus] = useState({
    users_total: 0,
    users_active: 0,
    users_inactive: 0,
    users_archived: 0,
  });
  // GET /api/status
  //  users_total: 3, users_active: 2, users_inactive: 1, users_archived: 2
  //============= API request
  const getData = useCallback(async () => {
    try {
      const data = await request('/api/status', 'GET', {});
      if (data.data) {
        setDataStatus(data.data);
        devLog('dashboard api', 'ok');
      }
      //JScriptInit("/assets/js/custom/datatable.js");
      // setDataUpdate(new Date().toISOString().slice(0, 16).toString());
    } finally {
    }
  }, []);

  //============= отслеживание
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setLoadingPage(loading);
  }, [loading, setLoadingPage]);

  //--- page, limit
  useEffect(() => {
    getData();
  }, [updatePage]);
  //=====
  if (accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loading} />
      <UITypographyH1 pageCode={pageCode} loading={loading} />
      {error && <UIAlertError text={error} />}

      <div className="row mt-4">
        <div className="col-sm-6 col-xl-3">
          <Link to="/user">
            <div className="p-3 bg-primary-200 rounded overflow-hidden position-relative text-white mb-g">
              <div className="">
                <h3 className="display-4 d-block l-h-n m-0 fw-500">
                  {datastatus.users_total}
                  <small className="m-0 l-h-n">users total</small>
                </h3>
              </div>
              <i
                className="fal fa-users position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
                style={{ fontSize: '6rem' }}
              ></i>
            </div>
          </Link>
        </div>
        <div className="col-sm-6 col-xl-3">
          <Link to="/user?active=on">
            <div className="p-3 bg-success-600  rounded overflow-hidden position-relative text-white mb-g">
              <div className="">
                <h3 className="display-4 d-block l-h-n m-0 fw-500">
                  {datastatus.users_active}
                  <small className="m-0 l-h-n">users active</small>
                </h3>
              </div>
              <i
                className="fal fa-user-check position-absolute pos-right pos-bottom opacity-15  mb-n1 mr-n1"
                style={{ fontSize: '6rem' }}
              ></i>
            </div>
          </Link>
        </div>
        <div className="col-sm-6 col-xl-3">
          <Link to="/user?active=off">
            <div className="p-3 bg-warning-400 rounded overflow-hidden position-relative text-white mb-g">
              <div className="">
                <h3 className="display-4 d-block l-h-n m-0 fw-500">
                  {datastatus.users_inactive}
                  <small className="m-0 l-h-n">users inactive</small>
                </h3>
              </div>
              <i
                className="fal fa-user-lock position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
                style={{ fontSize: '6rem' }}
              ></i>
            </div>
          </Link>
        </div>
        <div className="col-sm-6 col-xl-3">
          <Link to="/user?active=archive">
            <div className="p-3 bg-danger-200 rounded overflow-hidden position-relative text-white mb-g">
              <div className="">
                <h3 className="display-4 d-block l-h-n m-0 fw-500">
                  {datastatus.users_archived}
                  <small className="m-0 l-h-n">users archived</small>
                </h3>
              </div>
              <i
                className="fal fa-user-times position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
                style={{ fontSize: '6rem' }}
              ></i>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
