export interface IPagination {
  limit: number;
  page: number;
  total: number;
  pcount: number;
}
export const IPaginationDefault = { limit: 10, page: 1, total: 0, pcount: 0 };

export interface IdataPage {
  name: string;
  url: string;
  icon: string;
  text: string;
}
export interface IdateTime {
  $numberLong: string;
}
export interface IdateT {
  $date: IdateTime;
}
