import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Button, Input, Space, Table } from 'antd';
import type { GetRef, TableColumnsType, TableColumnType } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
// ---
import { Pagination } from '../shared/ui/Pagination';
import { useHttp } from '../hooks/http.hooks';
import { dataPageLimit } from '../app/data/default';
import { UIInfoNoAccess } from 'shared/ui/info';
import { IAuditItem } from 'entities/audit/models';
import { IPagination } from 'shared/models';
import { AuditDailyProvider } from '../pages/audit/Context';
import { WAuditTableDaily } from 'widgets/audit/TableDaily';
import moment from 'moment';
//---react-datepicker
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { UIAlertError } from 'shared/ui/alert';
import { usePageContext } from 'shared/context/page';
import { ApiAuditPostListDaily } from 'entities/audit/api';
import { useAuthContext } from 'shared/context/auth';
registerLocale('ru', ru);

export function TestListPage() {
  const pageCode: string = 'test.audit.daily';
  const { updatePage, setLoadingPage, setErrorPage, loadingPage, errorPage } = usePageContext();

  //const auth = useContext(AuthContext);
  const auth = useAuthContext();

  const { loading, request, error, success } = useHttp();

  const [auditlist, setAuditlist] = useState<IAuditItem[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    page: 1,
    total: 0,
    pcount: 0,
  });
  const [filter, setFilter] = useState({ searchdate: '', uname: '' });
  const [uname, setUname] = useState('');
  const [limit, setLimit] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [pagetotal, setPageTotal] = useState<number>(0);
  const [pagecount, setPageCount] = useState<number>(0);
  const [modalData, setModalData] = useState<IAuditItem>();
  //=====
  //var today = new Date();
  //var timeformat = today.toISOString().slice(0, 16);
  //console.log('today=' + timeformat.toString());
  //const [date1, setDate1] = useState(timeformat.toString());
  const [date1, setDate1] = useState(moment().local().subtract(1, 'hour').toDate());
  /*
        const changeDate1 = (event: React.ChangeEvent<HTMLInputElement>) => {
            console.log('changeDate1 =' + event.target.value);
            // console.log('changeDate1 =' + event.target.name);
            // console.log(typeof event.target.value);
            setDate1(event.target.value);
            setPage(1);
        }
        */
  const changeUname = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('changeUname =' + event.target.value);
    console.log('changeUname =' + event.target.name);
    console.log(typeof event.target.value);
    setUname(event.target.value);
    setPage(1);
  };
  const changeSelectLimit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let numberlimit = Number(event.target.value);
    setLimit(numberlimit);
    setPage(1);
  };

  const paginate = useCallback(
    (PageNumber: number) => {
      console.log('PageNumber', PageNumber);
      let PageNumberN = Number(PageNumber);
      //setPagination({ ...pagination, 'page': PageNumberN });
      setPage(PageNumberN);
      console.log('page', page);
      //getList();
      //SearchHandler();
    },
    [page],
  );

  // form
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('changeHandler =' + event.target.value);
    console.log('changeHandler =' + event.target.name);
    console.log(typeof event.target.value);
    setFilter({ ...filter, [event.target.name]: event.target.value });
    console.log('searchdate =' + filter.searchdate);
  };
  /*
    const changeSelectLimit:any = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPagination({ ...pagination, [event.target.name]: event.target.value });
    };
    */

  const changeHandlerData = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log('changeHandlerData =' + event.target.value);
    //console.log('changeHandlerData =' + event.target.name);
    //console.log( typeof event.target.value);
    //setDatePicker(event.target.value);
    // console.log('datepicker =' + datepicker);
  };

  const SearchHandler = () => {
    getList();
  };
  //----- old
  /*
    const getList = useCallback(
        async () => {
            try {

                //const ddd = date1 + ':00.000Z';
                //const searchdate = Date.parse(date1 + ':00.000Z');
                //const searchdate = Date.parse(date1.toISOString());
                //const searchdate=date1.getTime() - 60000*date1.getTimezoneOffset();
                //const data_input=moment(date1).utc().startOf('day'); // .parseZone().utc()
                const searchdate = moment(date1).unix() * 1000;//
                const data = await request('/api/audit/daily', 'POST', { 'page': page, 'limit': limit, 'searchdate': searchdate, 'uname': uname.trim() }); // , 'uname': uname 
                if (data.data) {
                    setAuditlist(data.data);
                    setPageTotal(data.total);
                    setPageCount(data.count);
                } else {
                    setPageTotal(0);
                    setPageCount(0);
                }


            } catch (e) {
                console.log(e);
            }
        }, [request, date1, filter, pagination, uname, page, limit]
    );
    */
  const {
    loading: loadingApiList,
    response: apiList,
    error: errorApiList,
  } = ApiAuditPostListDaily(); //  API
  const getList = useCallback(async () => {
    try {
      const searchdate = moment(date1).unix() * 1000; //
      const enddate = moment(date1).unix() * 1000 + 86400 * 1000;
      // dataReq - массив данных фильтра для запроса
      // let dataReq: any = { page: page, limit: limit, searchdate: searchdate, uname: uname.trim() };

      /*
          pub searchdate: i64,
    pub enddate: Option<i64>,
    pub page: Option<u8>,
    pub limit: Option<u16>,
    pub uname: Option<String>,
    pub id: Option<u16>,
    pub fuzzy_query: Option<String>,
      */
      let dataReq: any = { searchdate: searchdate, enddate: enddate };

      //==========
      const res = await apiList(dataReq); // POST LIST
      if (res.data) {
        setAuditlist(res.data);
        setPageTotal(res.total);
        setPageCount(res.count);
      } else {
        setPageTotal(0);
        setPageCount(0);
      }
    } finally {
    }
  }, [apiList, date1, uname, limit, page]);
  // =========================================================================== antd
  type InputRef = GetRef<typeof Input>;

  interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
  }

  type DataIndex = keyof DataType;

  const data: DataType[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      name: 'Joe Black',
      age: 42,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      name: 'Jim Green',
      age: 32,
      address: 'Sydney No. 1 Lake Park',
    },
    {
      key: '4',
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park',
    },
  ];
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<any>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<IAuditItem> = [
    {
      title: 'ID',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },

    {
      title: 'time',
      dataIndex: 'time',
      key: 'time',
    },
  ];

  //=============================================================================

  //============= отслеживание
  useEffect(() => {
    setLoadingPage(loadingApiList);
  }, [loadingApiList, setLoadingPage]);
  useEffect(() => {
    setErrorPage(errorApiList);
  }, [errorApiList, setErrorPage]);
  //--- page, limit
  useEffect(() => {
    getList();
  }, [updatePage, page, pagecount]);

  //
  //let dateFormat = new Intl.DateTimeFormat('ru-RU', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(filter.searchdate);
  //if (auth.userrole != "superadmin" && auth.userrole != "admin" && auth.userrole != "auditor") {return <UIInfoNoAccess />}
  if (auth.accessRights(pageCode) !== true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loadingPage} />
      <UITypographyH1 pageCode={pageCode} loading={loadingPage} />
      {errorPage && <UIAlertError text={errorPage} />}
      <AuditDailyProvider value={modalData}></AuditDailyProvider>
      <Table columns={columns} dataSource={auditlist} />
      <div className="row">
        <div className="col-xl-12">
          <div id="panel-1" className="panel">
            <div className="panel-container show">
              <div className="panel-content">
                <div className="panel-tag">
                  <form onSubmit={submitHandler}>
                    <div className="row">
                      <div className="col-xl-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="date1">
                            Дата и время [dd.mm.yyyy]
                          </label>
                          {/*<input onChange={changeDate1} name="date1" id="date1" className="form-control" type="datetime-local" defaultValue={date1} />*/}
                          <DatePicker
                            locale="ru"
                            className="form-control"
                            selected={date1}
                            onChange={(val: any) => setDate1(val)}
                            dateFormat="dd.MM.yyyy"
                          />
                        </div>
                      </div>
                      <div className="col-xl-3  ">
                        <div className="form-group">
                          <label className="form-label" htmlFor="uname">
                            uname
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="uname"
                            name="uname"
                            defaultValue={uname}
                            onChange={changeUname}
                          />
                        </div>
                      </div>

                      <div className="col-xl-3  ">
                        <div className="form-group">
                          <label className="form-label" htmlFor="limit">
                            на странице
                          </label>
                          <select
                            className="form-control"
                            id="limit"
                            name="limit"
                            onChange={changeSelectLimit}
                            defaultValue={limit}
                          >
                            {dataPageLimit.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-3  "></div>
                      <div className="col-xl-3  ">
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-themed "
                          onClick={SearchHandler}
                          disabled={loading}
                        >
                          Применить
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <hr />
                <Pagination
                  limit={limit}
                  selected={page}
                  total={pagetotal}
                  pcount={pagecount}
                  paginate={paginate}
                />
                {/*===========================*/}
                <WAuditTableDaily list={auditlist} />
                {/*
                                {!auditlist.length && <div className="text-center">За указанный период данных нет</div>}
                                {auditlist.length > 0 && <table id="dt-basic-example" className="table table-bordered table-hover table-striped w-100">
                                    <thead className="bg-primary-600">
                                        <tr>
                                            <th>time</th>
                                            <th>ID</th>
                                            <th>OUID</th>
                                            <th>uname</th>
                                            <th>msg</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {auditlist.map(item => {
                                            return (
                                                <tr key={item._id}>
                                                    <td> 
                                                        {moment(item.time).local().format('DD.MM.YYYY HH:mm:ss')}
                                                    </td>
                                                    <td>{item.ID}</td>
                                                    <td>{item.OUID}</td>

                                                    <td>{item.uname}</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="p-2 w-100 ">
                                                                {item.msg}
                                                            </div>
                                                            <div className="p-2 flex-shrink-1  ">
                                                                <button className="btn btn-default btn-lg btn-icon waves-effect waves-themed"
                                                                    data-toggle="modal" data-target=".default-example-modal-right"
                                                                    onClick={() => { setModalData(item) }}
                                                                >
                                                                    <i className="fal fa-info"></i>
                                                                </button>
                                                                <button className="btn btn-sm btn-outline-default waves-effect waves-themed" type="button" data-toggle="collapse" data-target={'#collapse' + item._id} aria-expanded="false" aria-controls={'collapse' + item._id}>
                                                                    <i className="fal fa-angle-down "></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="collapse" id={'collapse' + item._id}>
                                                            <div className="card card-body auditlist-collapse-span">
                                                                <div className="row ">
                                                                    <div className="col-sm-3"> <span>_id:</span>   {item._id} </div>
                                                                    <div className="col-sm-3"> <span>addr:</span>   {item.addr} </div>
                                                                    <div className="col-sm-3">   <span>class:</span>  {item.class}</div>
                                                                    <div className="col-sm-3">   <span>facility:</span> {item.facility}</div>
                                                                    <div className="col-sm-3">   <span>geo:</span> {item.geo}</div>
                                                                    <div className="col-sm-3">   <span>level:</span> {item.level}</div>
                                                                    <div className="col-sm-3">  <span>module: </span>{item.module}</div>
                                                                    <div className="col-sm-3">   <span>req:</span> {item.req}</div>
                                                                    <div className="col-sm-3">  <span>res:</span> {item.res}</div>
                                                                </div>
                                                            </div>
                                                        </div>







                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>time</th>
                                            <th>ID</th>
                                            <th>OUID</th>
                                            <th>uname</th>
                                            <th>msg</th>

                                        </tr>
                                    </tfoot>
                                </table>}
                            */}
                {/*===========================*/}
                <Pagination
                  limit={limit}
                  selected={page}
                  total={pagetotal}
                  pcount={pagecount}
                  paginate={paginate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
