import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthPage } from './auth/Login';
import { DashboardPage } from './Dashboard';
import { UserDatailPage } from './UserDatailPage';
import { AuditListPage } from './audit/ListDaily';
import { NotFoundPage } from './NotFoundPage';
import { UserListPage } from './users/List';
import { UserNewPage } from './users/New';
import { AuditListHour } from './audit/ListHour';
import { PageConnectorLDAPlist } from './connectorLDAP/List';
import { PageConnectorLDAPnew } from './connectorLDAP/New';
import { PageConnectorLDAPupdate } from './connectorLDAP/Update';
import { PageConnectorLDAPlistusers } from './connectorLDAP/ListUsers';
import { AuditListPoriod } from './audit/ListPeriod';
import { PageNEmailServersList } from './NotificationEmail/ServersList';
import { PageNEmailTemplatesList } from './NotificationEmail/TemplatesList';
import { PageNEmailEventsList } from './NotificationEmail/EventsList';
import { PageNEmailServersNew } from './NotificationEmail/ServersNew';
import { PageNEmailServersUpdate } from './NotificationEmail/ServersUpdate';
import { PageNEmailTemplatesNew } from './NotificationEmail/TemplatesNew';
import { PageNEmailTemplatesUpdate } from './NotificationEmail/TemplatesUpdate';
import { TestListPage } from 'test/pageList';
import { UsersPageUpdate } from 'modules/users/update';
import { AuditPageConfig } from 'modules/audit/config';
import { ReportPageNotActivated } from './users/NotActivated';
import { ReportPageNotLinked } from './users/NotLinked';
import { PageBypass } from 'modules/bypass/pages';

export const usePagesRoutes = (isAuthenticated: boolean) => {
  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<DashboardPage />} />

        <Route path="/audit">
          <Route index element={<AuditListPage />} />
          <Route path="day" element={<AuditListPage />} />
          <Route path="hour" element={<AuditListHour />} />
          <Route path="period" element={<AuditListPoriod />} />
          <Route path="config" element={<AuditPageConfig />} />
        </Route>

        <Route path="/user">
          <Route index element={<UserListPage />} />
          <Route path=":id" element={<UserDatailPage />} />
          <Route path="update/:id" element={<UsersPageUpdate />} />
          <Route path="new" element={<UserNewPage />} />
        </Route>
        <Route path="/report">
          <Route index element={<ReportPageNotActivated />} />
          <Route path="not-activated" element={<ReportPageNotActivated />} />
          <Route path="not-linked" element={<ReportPageNotLinked />} />
        </Route>

        <Route path="/settings">
          <Route index element={<PageConnectorLDAPlist />} />
          <Route path="connector-ldap" element={<PageConnectorLDAPlist />} />
          <Route path="connector-ldap-new" element={<PageConnectorLDAPnew />} />
          <Route path="connector-ldap-update/:id" element={<PageConnectorLDAPupdate />} />
          <Route path="connector-ldap-users/:id" element={<PageConnectorLDAPlistusers />} />
          <Route path="notification-email">
            <Route index element={<PageNEmailServersList />} />
            <Route path="servers">
              <Route index element={<PageNEmailServersList />} />
              <Route path="list" element={<PageNEmailServersList />} />
              <Route path="new" element={<PageNEmailServersNew />} />
              <Route path="update/:id" element={<PageNEmailServersUpdate />} />
            </Route>
            <Route path="templates">
              <Route index element={<PageNEmailTemplatesList />} />
              <Route path="list" element={<PageNEmailTemplatesList />} />
              <Route path="new" element={<PageNEmailTemplatesNew />} />
              <Route path="update/:id" element={<PageNEmailTemplatesUpdate />} />
            </Route>
            <Route path="events">
              <Route index element={<PageNEmailEventsList />} />
              <Route path="list" element={<PageNEmailEventsList />} />
            </Route>
          </Route>
          <Route path="emergency-bypass" element={<PageBypass />} />
        </Route>
        <Route path="/test" element={<TestListPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<AuthPage />} />
        <Route path="/login" element={<AuthPage />} />
        <Route path="/logout" element={<AuthPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }
};
