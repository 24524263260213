import { createContext, useContext, useState } from "react";
import { IPagination, IPaginationDefault } from "shared/models";

//----- Modal right
const AuditModalRContext = createContext({});
export const useAuditModalR = () => {
    return useContext(AuditModalRContext);
}
//----- 
const Pagination = createContext(IPaginationDefault);
export const usePagination = () => {
    return useContext(Pagination);
}
//--------------------------------
export const AuditDailyProvider = ({ children }: any) => {
    const [modalData, setModalData] = useState({});
   
    const [pagination, setPagination] = useState<IPagination>(IPaginationDefault);
    return (
        <Pagination.Provider value={pagination}>
            <AuditModalRContext.Provider value={modalData}>
                {children}
            </AuditModalRContext.Provider>
        </Pagination.Provider>
    );
}