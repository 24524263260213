import { AuthContext } from 'entities/users/AuthContext';
import { useHttp } from 'hooks/http.hooks';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthContext } from 'shared/context/auth';
import { usePageContext } from 'shared/context/page';
import { UIInfoNoAccess } from 'shared/ui/info';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';

export const AuditPageConfig = () => {
  const pageCode: string = 'audit.config';
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  // const language = 'ru';
  //============
  const { loading, request } = useHttp();
  const { updatePage } = usePageContext();
  const [list, setlist] = useState([]);

  //============= API request

  const getData = useCallback(async () => {
    try {
      const data = await request('/api/audit/config', 'GET', {});
      if (data.config) {
        // data.data.verifypass=data.data.verify_pass;
        //setlist(data.config);
        var keys: any = [];
        var result: any = [];
        for (var k in data.config) {
          if (data.config.hasOwnProperty(k)) {
            keys.push(k);
          }
        }

        keys.sort();
        var len = keys.length;
        for (var i = 0; i < len; i++) {
          console.log(keys[i] + ':' + data.config[keys[i]]);
          result[keys[i]] = data.config[keys[i]];
        }
        setlist(result);

        //setlist(listSorted);
        //console.log(JSON.stringify(listSorted));
      }
      //if (error) { setMsgErrorStatus(error); }
      //setDataUpdate(funcDateNow());
    } catch (e) {
      console.log(e);
      //setMsgErrorStatus(LANG_default[language].error500);
    }
  }, [request]);
  //---

  //---
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [updatePage]);

  if (auth.accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loading} />
      <UITypographyH1 pageCode={pageCode} loading={loading} />
      <div className="row ">
        <div className="col-6 ">
          <div id="panel-4" className="panel">
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row ">
                  <div className="col-12 ">
                    <table className="table table-striped m-0">
                      <tbody>
                        {Object.entries(list).map(([key, value]) => (
                          <tr key={key}>
                            <td>{key}:</td>
                            <td>{value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
