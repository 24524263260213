import { action, makeAutoObservable, runInAction } from 'mobx';

class PageStore {
  //============= list
  listDefault: any[] = [];
  list: any[] = [];
  flaglistDefaultNeedToUpdate: boolean = false;
  //============= search
  searchQuery = '';
  apiStatus: 'idle' | 'loading' | 'success' | 'error' = 'idle';
  //============= Pagination
  paginationTotalItem = 0;
  paginationLimit = 50;
  paginationSelected = 1;

  constructor() {
    makeAutoObservable(this);
  }
  setNewPage() {
    this.listDefault = [];
    this.list = [];
    this.searchQuery = '';
    this.paginationTotalItem = 0;
    this.paginationLimit = 50;
    this.paginationSelected = 1;
  }
  //============= list
  //-----до поиска
  setListDefault(newList: any) {
    this.list = newList;
    this.listDefault = newList;
    this.paginationTotalItem = newList.length;
    this.paginationSelected = 1;
  }

  // Добавляем action для изменения списка
  setListUpdateTmp(newList: any[]) {
    this.list = newList;
  }
  //-----до в результате поиска
  setList(newList: any) {
    this.list = newList;
    this.paginationTotalItem = newList.length;
    this.paginationSelected = 1;
  }
  //============= Search
  setSearchQuery(query: string) {
    this.searchQuery = query;
    this.paginationSelected = 1;
  }
  //============= Pagination
  setPaginationDefault() {
    this.paginationSelected = 1;
  }
  setPaginationLimit(query: number) {
    this.paginationLimit = query;
    this.paginationSelected = 1;
  }
  setpaginationTotalItem(query: number) {
    this.paginationTotalItem = query;
    this.paginationSelected = 1;
  }
  setSelectedPage(page: number) {
    this.paginationSelected = page;
  }
  setSelectedNextPage() {
    this.paginationSelected = this.paginationSelected + 1;
  }
  //=============
  setApiStatus(status: 'idle' | 'loading' | 'success' | 'error') {
    this.apiStatus = status;
  }
  //============================при импорте менем список без перезагрузки.
  setFlaglistDefaultNeedToUpdate(val: boolean) {
    this.flaglistDefaultNeedToUpdate = val;
  }
  // Action для изменения local_id при импорте.
  setListLocalID = (login: string, newLocalId: string, upn_as_login: boolean) => {
    let listTmp = this.list;
    if (upn_as_login) {
      const item = listTmp.find((el) => el.upn.toLowerCase() === login); // Находим нужный объект
      if (item) {
        runInAction(() => {
          item.local_id = newLocalId; // Меняем local_id внутри runInAction
          item.linked = true;
        });
      }
    } else {
      const item = listTmp.find((el) => el.login.toLowerCase() === login); // Находим нужный объект
      if (item) {
        runInAction(() => {
          item.local_id = newLocalId; // Меняем local_id внутри runInAction
          item.linked = true;
        });
      }
    }

    this.setListUpdateTmp(listTmp); // Применяем изменения в списке
    this.flaglistDefaultNeedToUpdate = true;
  };
}

const pageStore = new PageStore();
export default pageStore;
