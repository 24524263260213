import { userRoles } from 'app/data/default';

export const localstoreDedault = {
  userId: null,
  token: null,
  userlogin: null,
  userrole: userRoles[0],
  version: null,
  bypass: false,
  bypassavail: false,
};
