import { Alert, message } from 'antd';
import { LANG_default } from 'app/data/lang';
import { useHttp } from 'hooks/http.hooks';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'shared/context/auth';
import { UIAlertErrorSM, UIAlertSuccessSM } from 'shared/ui/alert';
import { FormCheckbox, FormPreventDefault } from 'shared/ui/form';
import { UIInfoLoader } from 'shared/ui/info';

type ICLDAPUpdateConfigTrack = {
  id: string | undefined;
  name: string;
  value: boolean;
};

export function WCLDAPUpdateConfigTrack({ id, name, value }: ICLDAPUpdateConfigTrack) {
  const language = 'ru';
  const [messageApi, contextHolder] = message.useMessage();

  const { loading, request, error } = useHttp();
  //-----
  const [data, setData] = useState(false);
  const [msgsuccess, setMsgSuccess] = useState('');
  const [msgerror, setMsgError] = useState('');
  //-------------
  function cleanMsg() {
    setMsgSuccess('');
    setMsgError('');
  }
  //-----
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    save(event.target.checked);
  };
  //------
  const save = async (newData: boolean) => {
    cleanMsg();
    try {
      if (id != undefined) {
        const dataconfig = await request('/api/ldap/' + id + '/update', 'PATCH', {
          config: {
            [name]: newData,
          },
        });
        setData(newData);
        // setMsgSuccess(`Сохранено ${name}!`);
        messageApi.open({
          type: 'success',
          content: `Сохранено ${name}!`,
        });
      }
    } catch (e) {
      console.log(e);
      setMsgError(LANG_default[language].error500);
      messageApi.open({
        type: 'error',
        content: LANG_default[language].error500,
      });
    }
  };
  //------
  useEffect(() => {
    setData(value);
  }, [value]);
  //------
  /*
  useEffect(() => {
    setTimeout(() => {
      cleanMsg();
    }, 4000);
  }, [msgsuccess, msgerror]);
*/

  //------
  return (
    <>
      {contextHolder}
      <div className="row ">
        <div className="col-2">
          <FormCheckbox label="" name={name} fOnChange={changeHandler} value={data} typeswitch={true} />
        </div>
        <div className="col-10">
          {loading && <UIInfoLoader />}
          {msgerror && <Alert message={msgerror} type="error" closable />}
        </div>
      </div>
    </>
  );
}
