import { useCallback } from 'react';
import { ApiBypassGetVoteEnable } from '../api';
import { devLog } from 'shared/lib/default';
import { UIAlertErrorSM } from 'shared/ui/alert';
import { UIInfoLoader } from 'shared/ui/info';
import { usePageContext } from 'shared/context/page';

export const WBypassVoteEnable = () => {
  //=============
  const { refreshPage } = usePageContext();
  const { loading, response, error, success } = ApiBypassGetVoteEnable();
  const ButtonClick = () => {
    dataList();
  };
  const dataList = useCallback(async () => {
    try {
      const res = await response();
      devLog('data&&', JSON.stringify(res));
      refreshPage();
    } catch (e) {
      if (e instanceof Error) {
        devLog('WBypassVoteEnable', JSON.stringify(e));
      }
    }
  }, [response]);
  //=============

  //=============

  return (
    <>
      <div className="row">
        <div className="col-xl-6">
          <button
            className="btn btn-primary waves-effect waves-themed  mr-2"
            onClick={ButtonClick}
            disabled={loading}
          >
            Включить {loading && <UIInfoLoader />}
          </button>
        </div>
        <div className="col-xl-6">
          {error && <UIAlertErrorSM text={error} />}
          {/*success && <UIAlertSuccessSM text={success} />*/}
        </div>
      </div>
    </>
  );
};
