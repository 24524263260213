export interface  INEmailServersNew{
    name:string;
    sender_name:string;
    sender_email:string;
    transportType:string;
    smtp_host:string;
    smtp_port:string;
    smtp_login:string;
    smtp_passw:string;
    smtp_ignore_sert:boolean;
    smtp_tls_version:string;
    postal_url:string;
    postal_key:string;

}
export const INEmailServersNewDefault={
    name:'',
    sender_name:'',
    sender_email:'',
    transportType:'SmtpInsecure',
    smtp_host:'',
    smtp_port:'',
    smtp_login:'',
    smtp_passw:'',
    smtp_ignore_sert:false,
    smtp_tls_version: 'Tlsv12',
    postal_url:'',
    postal_key:''

}