import { useHttp } from "hooks/http.hooks";
import { useCallback, useState } from "react";
import { devLog } from "shared/lib/default";
interface IApiUserUpdate {
    user_id: string;
}
export const ApiUserUpdate = ({ user_id }: IApiUserUpdate) => {
    const url = '/api/user/' + user_id + '/update';
    devLog("user_id",user_id);
    const textSuccess = 'Данные успешно сохранены';
    //----
    const { loading, request, error } = useHttp();
    const [success, setSuccess] = useState('');
    //-------
    const response = useCallback(
        async (data:any) => {
            try {
                const dataList = await request(url, 'PUT', data);
                devLog("ApiUserUpdate " + url, JSON.stringify(dataList));
                if (error) {
                    devLog('error', error);
                    throw new Error(error || 'Ошибка http запроса');
                }
                setSuccess(textSuccess);
                devLog('success', textSuccess);
                if (dataList.total > 0) { }
                return dataList;
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
                throw e;
            }
        }, [request]);
    return { loading, response, error, success };
}