import { dataDefault } from 'app/data/default';
import { ApiBypassGetStatus } from 'modules/bypass/api';
import { BypassStatusDefault } from 'modules/bypass/models';
import { MBypassLibGetLocalStor } from 'modules/bypass/shared/lib';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SLocalstoreUpdate } from 'shared/lib/localstore';
import { noop, noopVal } from 'shared/types';
// USE
// const {updatePage,refreshPage}=usePageContext();
/*
------ Флаг обновить страницу
updatePage - bool, флаг обновить страницу,
refreshPage - инвертирует флаг, для отслеживания события
------
*/

const PageContext = createContext({
  updatePage: false,
  refreshPage: noop,
  loadingPage: false,
  setLoadingPage: noopVal,
  errorPage: '',
  setErrorPage: noopVal,
  successPage: '',
  setSuccessPage: noopVal,
  globalBypassavail: false,
  setPageBypassavail: noopVal,
  globalBypass: false,
  setPageBypass: noopVal,
  systemName: '',
  setSystemName: noopVal,
});

export const usePageContext = () => {
  return useContext(PageContext);
};

export const PageProvider = ({ children }: any) => {
  const [updatePage, setUpdatePage] = useState(false); // флаг обновления страницы
  const refreshPage = () => {
    setUpdatePage((prev) => !prev);
  };
  //-----
  const [loadingPage, setLoading] = useState(false);
  const setLoadingPage = (val: boolean) => setLoading(val);
  const [errorPage, setError] = useState('');
  const setErrorPage = (val: any) => setError(val);
  const [successPage, setSuccess] = useState('');
  const setSuccessPage = (val: any) => setSuccess(val);
  //-----
  const [globalBypassavail, setBypassavail] = useState(false);
  const setPageBypassavail = (val: boolean) => setBypassavail(val);
  const [globalBypass, setBypass] = useState(false);
  const setPageBypass = (val: boolean) => setBypass(val);
  const [systemName, setSystemName] = useState('');

  //const bypassavail = useRef<boolean>(false); // bypassavail.current
  //const bypass = useRef<boolean>(false); // bypass.current
  /*
  //-----
  const { loading, response, error, success } = ApiBypassGetStatus();
  //-----
  const BypassGetStatus = useCallback(async () => {
    try {
      const res = await response();
      const dataold = MBypassLibGetLocalStor();
      if (dataold.bypass != res.data.state) {
        SLocalstoreUpdate({ bypass: res.data.state });
        //bypass.current = res.data.state;
        console.log('pagecontext bypass', res.data.state);
      }
    } catch (e) {
      if (e instanceof Error) {
      }
    }
  }, [response]);
  */
  //-----
  //============
  useEffect(() => {
    // BypassGetStatus();
    /*
    const timerId = setInterval(() => {
      //BypassGetStatus();
    }, 5500);
    */
  }, []);

  //-----
  return (
    <PageContext.Provider
      value={{
        updatePage,
        refreshPage,
        loadingPage,
        setLoadingPage,
        errorPage,
        setErrorPage,
        successPage,
        setSuccessPage,
        globalBypassavail,
        setPageBypassavail,
        globalBypass,
        setPageBypass,
        systemName,
        setSystemName,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
