import { createContext, useContext, useEffect, useRef, useState } from "react";
import { noopVal } from "shared/types";
import { InputNull, SelectNull } from "shared/forms";
import { useLocation } from "react-router-dom";
import { usePageContext } from "shared/context/page";

export const FormDefaltUserList = { active: '', role: '', fuzzyquery: '' };
export const ContextUserList = createContext(
    {
        form: FormDefaltUserList,
        setForm: noopVal,
        saveInput: InputNull,
        saveSelect: SelectNull,
        page: 1,
        setPage: noopVal
    }
);
export const useContextUsersList=()=>{
    return useContext(ContextUserList);
}
// const {form,setForm,saveInput,saveSelect,page,setPage}=useContextUsersList();
export const ContextProviderUsersList = ({children}:any) => {
     

    //=====
    const [form, setFormData] = useState(FormDefaltUserList);
    //---
    function setForm(val:any){setFormData(val)}
    //---
    const saveInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...form, [event.target.name]: event.target.value });
    }
    //---
    const saveSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...form, [event.target.name]: event.target.value });
    }
        //----- Чтение параметров из url
    //console.log('location.pathname=',location.pathname);     // адрес текущей страницы для чтения параметров ?active=...
    const location = useLocation();
    const statusUrl = useRef('');
    const urlParamsActive = new URLSearchParams(location.search).get("active"); //USE ?active=on|off|archive
    if (urlParamsActive !== statusUrl.current && urlParamsActive != null) {
        //devLog('URLSearchParams ', urlParamsActive);
        statusUrl.current = urlParamsActive;
        setFormData({ ...form, ['active']: statusUrl.current });
    }
    //=====
    const [page, setPageData] = useState(1);
    //---
    function setPage(val:any){setPageData(val)}
 
    return (
        <ContextUserList.Provider value={{form,setForm,saveInput,saveSelect,page,setPage}}>
            {children}
        </ContextUserList.Provider>
    );
}