import { useCallback, useEffect, useState } from "react";
//import * as bootstrap from 'bootstrap';

export const useMessage = () => {
    const nrandom = Math.random();
    //============= отслеживание
    useEffect(() => {
        //const toastEl = document.getElementById('toast-' + nrandom);
        //let toast = new bootstrap.Toast(toastEl);
        //toast.show();

    }, []);
    return useCallback(
        (text) => {
            <div class="toast position-absolute pos-right show"
            role="alert" aria-live="assertive" aria-atomic="true" data-autohide="true"
            style={{ position: 'absolute', zIndex: '2000', right: '30px' }}
            >
                <div className="toast-header">
                    <strong className="mr-auto">Bootstrap</strong>
                    <small>11 mins ago</small>
                    <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {text}
                </div>
            </div>
        }, []
    )
}