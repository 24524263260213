import { IUserItem, IUserItemDefault } from 'entities/users/models';
import moment from 'moment';
import { useState } from 'react';

type TModalRightInfoButton = {
  item: IUserItem;
};

export const useUserModalRightInfo = () => {
  const [modalData, setModalData] = useState<IUserItem>(IUserItemDefault); // модальное окно для INFO
  //========
  function UserModalRightInfoButton({ item }: TModalRightInfoButton) {
    return (
      <button
        type="button"
        className="btn btn-outline-primary  waves-effect waves-themed"
        data-toggle="modal"
        data-target=".user-info-modal-right"
        onClick={() => {
          setModalData(item);
        }}
      >
        <i className="fal fal fa-info"></i>
      </button>
    );
  }
  //========
  function UserModalRightInfo() {
    return (
      <>
        {/*  Modal*/}
        <div className="modal fade user-info-modal-right" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-right">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title h4">Детальная информация **</h5>
                {/*onClick={() => { setModalData({}); }} */}
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <i className="fal fa-times"></i>
                  </span>
                </button>
              </div>
              <div className="modal-body audit-detail">
                <table className="table table-bordered table-hover table-striped w-100">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>{modalData._id}</td>
                    </tr>
                    <tr>
                      <td>login</td>
                      <td>{modalData.login}</td>
                    </tr>
                    <tr>
                      <td>name</td>
                      <td>{modalData.name}</td>
                    </tr>
                    <tr>
                      <td>email</td>
                      <td>{modalData.email}</td>
                    </tr>
                    <tr>
                      <td>роль</td>
                      <td>{modalData.role}</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                {modalData.connector && (
                  <>
                    <h3 className="color-primary-300">
                      {/*color-primary-300*/}
                      <b>Привязка к коннектору</b>
                    </h3>
                    <table className="table table-bordered table-hover table-striped w-100">
                      <tbody>
                        <tr>
                          <td>коннектор</td>
                          <td>{modalData.connector}</td>
                        </tr>
                        {modalData.sid && (
                          <tr>
                            <td>sid</td>
                            <td>{modalData.sid}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <hr />
                  </>
                )}
                {(modalData.expiry != undefined || modalData.password_expiry != undefined) && (
                  <>
                    <h3 className="color-primary-300">
                      {/*color-primary-300*/}
                      <b>Сроки действия</b>
                    </h3>
                    <table className="table table-bordered table-hover table-striped w-100">
                      <tbody>
                        {modalData.expiry != undefined && (
                          <tr>
                            <td>Учетной записи:</td>
                            <td>
                              {modalData.expiry == null && <>нет</>}
                              {modalData.expiry != null && <>{moment(Number(modalData.expiry.$date.$numberLong)).local().format('DD.MM.YYYY HH:mm:ss')}</>}
                            </td>
                          </tr>
                        )}
                        {modalData.password_expiry != undefined && (
                          <tr>
                            <td>Пароля:</td>
                            <td>
                              {modalData.password_expiry == null && <>нет</>}
                              {modalData.password_expiry != null && <>{moment(Number(modalData.password_expiry.$date.$numberLong)).local().format('DD.MM.YYYY HH:mm:ss')}</>}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <hr />
                  </>
                )}

                <h3 className="color-primary-300">
                  {/*color-primary-300*/}
                  <b>
                    Группы
                    {modalData.groups && <span className="badge bg-primary-300 ml-2">{modalData.groups.length}</span>}
                  </b>
                </h3>
                <table className="table table-bordered table-hover table-striped w-100">
                  <tbody>
                    {modalData.groups &&
                      modalData.groups.map((itemGroup: any) => {
                        return (
                          <>
                            <tr>
                              <td>{itemGroup}</td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  //========
  return { UserModalRightInfoButton, UserModalRightInfo };
};

//====================================================
type ButtonClick = {
  func: () => void;
};
export function FUsersModalRightIngoButton({ func }: ButtonClick) {
  return (
    <button type="button" className="btn btn-outline-primary  waves-effect waves-themed" data-toggle="modal" data-target=".default-example-modal-right" onClick={func}>
      <i className="fal fal fa-info"></i>
    </button>
  );
}

type IUserModalRightInfo = {
  modalData: IUserItem;
  dataTarget: string;
};

/*  USE
<FUserModalRightInfo modalData={modalData} dataTarget="default-example-modal-right" />
*/
export function FUserModalRightInfo({ modalData, dataTarget }: IUserModalRightInfo) {
  return (
    <>
      {/*  Modal*/}
      <div className={`modal fade ${dataTarget}`} tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-right">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title h4">Детальная информация</h5>
              {/*onClick={() => { setModalData({}); }} */}
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                  <i className="fal fa-times"></i>
                </span>
              </button>
            </div>
            <div className="modal-body audit-detail">
              <table className="table table-bordered table-hover table-striped w-100">
                <tbody>
                  <tr>
                    <td>id</td>
                    <td>{modalData._id}</td>
                  </tr>
                  <tr>
                    <td>login</td>
                    <td>{modalData.login}</td>
                  </tr>
                  <tr>
                    <td>name</td>
                    <td>{modalData.name}</td>
                  </tr>
                  <tr>
                    <td>email</td>
                    <td>{modalData.email}</td>
                  </tr>
                  <tr>
                    <td>роль</td>
                    <td>{modalData.role}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <h3 className="color-primary-300">
                {/*color-primary-300*/}
                <b>
                  Группы
                  {modalData.groups && <span className="badge bg-primary-300 ml-2">{modalData.groups.length}</span>}
                </b>
              </h3>
              <table className="table table-bordered table-hover table-striped w-100">
                <tbody>
                  {modalData.groups &&
                    modalData.groups.map((itemGroup: any) => {
                      return (
                        <>
                          <tr>
                            <td>{itemGroup}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
