import { AuthContext } from 'entities/users/AuthContext';
import { FNEmailServerNew } from 'features/NotificationEmail/FormServerNew';
import { FNotificationEmailNav } from 'features/NotificationEmail/NavTabs';
import { useContext } from 'react';
import { useAuthContext } from 'shared/context/auth';
import { UIInfoNoAccess } from 'shared/ui/info';
import { UITypographyH1 } from 'shared/ui/typography';

export const PageNEmailServersNew = () => {
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const pageCode: string = 'settings.notification.email.servers.new';
  //-------------
  if (auth.accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UITypographyH1 pageCode={pageCode} />
      {/*error && <UIAlertError text={error} />*/}
      {(auth.userrole == 'superadmin' || auth.userrole == 'admin') && (
        <div>
          <div className="panel-container show">
            <div className="panel-content">
              <div className="card border">
                <FNotificationEmailNav />
                <div className="card-body">
                  {/*=============================*/}
                  <div className="row">
                    <div className="col-6 offset-3 ptb-30">
                      <FNEmailServerNew />
                    </div>
                  </div>
                  {/*=============================*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
