import { useEffect, useState } from 'react';
import { useHttp } from 'hooks/http.hooks';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { usePageContext } from 'shared/context/page';
import { UIAlertError } from 'shared/ui/alert';
import { UIInfoNoAccess } from 'shared/ui/info';
import { WBypassStatus } from '../widgets/WBypassStatus';
import { useAuthContext } from 'shared/context/auth';
//==============================================
export const PageBypass = () => {
  //============= данные о странице
  const pageCode: string = 'settings.bypass';
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const [update, setUpdate] = useState(false); // флаг обновления страницы
  const { updatePage, loadingPage, errorPage, setLoadingPage, setErrorPage } = usePageContext();
  //-------
  const { loading, request, error } = useHttp();
  const [datastatus, setDataStatus] = useState({
    users_total: 0,
    users_active: 0,
    users_inactive: 0,
    users_archived: 0,
  });
  // GET /api/status
  //  users_total: 3, users_active: 2, users_inactive: 1, users_archived: 2
  //============= API request

  //============= отслеживание

  useEffect(() => {
    setLoadingPage(loading);
  }, [loading, setLoadingPage]);

  //---
  useEffect(() => {
    // getData();
  }, [updatePage]);
  //=====
  if (auth.accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loading} />

      <UITypographyH1 pageCode={pageCode} loading={loading} />
      {error && <UIAlertError text={error} />}

      <WBypassStatus />

      {/*
      <WBypassStatus />
      <WBypassVoteEnable />
      <WBypassRevokeEnable />
      <WBypassVoteDisable />
  <WBypassRevokeDisable />*/}
    </>
  );
};
